import { InpostGeowidgetReact } from 'inpost-geowidget-react'

const Inpost = ({
    config = 'parcelCollect',
    sandbox = false,
    language = 'pl',
}) => {
  const apiReady = (api) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          api.changePosition({ longitude, latitude }, 16)
        },
        (error) => {
          console.error("Error getting the user's location: ", error)
          api.changePosition({ longitude: 20.318968, latitude: 49.731131 }, 16)
        }
      )
    } else {
      console.error('Geolocation is not supported by this browser.')
      api.changePosition({ longitude: 20.318968, latitude: 49.731131 }, 16)
    }
  }
  // const pointSelect = (point) => {}
  return (
    <InpostGeowidgetReact
      config={config}
      sandbox={sandbox}
      language={language}
      token={process.env.REACT_APP_GEOWIGET}
      identifier="Geo1"
      apiReady={apiReady}
      // pointSelect={pointSelect}
    />
  )
}

export default Inpost
