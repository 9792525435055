import React, {useRef} from 'react'
import {SnackbarProvider} from 'notistack'

const ToastProvider = ({ children, theme }) => {
  const snackRef = useRef()
  const style = {
    buttonX: {
      color: 'black'
    }
  }
  return (
    <SnackbarProvider
      ref={snackRef}
      autoHideDuration={1500}
      maxSnack={theme?.config?.maxToasts || 2}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      hideIconVariant={false}
      dense={false}
    >
      {children}
    </SnackbarProvider>
  )
}

export default ToastProvider
