import {useEffect, useState} from 'react'
import {Avatar, Grid, Typography} from '@mui/material'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import {changeDataUser} from '../../firebase'
import {useSnackbar} from 'notistack'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import Tabs from '../../components/molecules/Tabs/Tabs'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import Seo from '../../components/molecules/Seo/Seo'
import {useTheme} from '@mui/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {useHistory} from 'react-router'
import UserHelper from '../../helpers/UserHelper/UserHelper'

const ProfilePage = (props) => {
  const theme = useTheme()
  const history = useHistory()
  const t = LocalStorageHelper.get('translation')
  const user = LocalStorageHelper.get('user')
  const {enqueueSnackbar} = useSnackbar()
  const style = {
    root: {
      display: 'flex',
      marginTop: 10,
      padding: BreakpointsHelper.isMobile() ? '15px' : '15px 50px'
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '500px'
    }
  }
  const formData = {
    elements: [
      {
        name: 'name',
        type: 'text',
        label: t.globals.inputs.name.label,
        disabled: true,
        value: user?.name || '',
        validationType: 'string'
      },
      {
        name: 'email',
        type: 'email',
        label: t.globals.inputs.email.label,
        disabled: true,
        value: user?.email || '',
        validationType: 'string'
      },
      {
        name: 'password',
        type: 'password',
        label: t.globals.inputs.password.label,
        helperText: t.globals.inputs.password.helperText,
        validationType: 'string'
      },
      {
        name: 'phone',
        type: 'phone',
        disabled: true,
        label: t.globals.inputs.phone.label,
        value: user?.phone || '',
        validationType: 'string'
      },
      {
        type: 'button',
        value: t.globals.save
      }
    ]
  }
  const handleChangeDataSubmit = (e) => {
    const data = {
      name: e.name,
      phone: e.phone,
      street: e.street,
      apartment: e.apartment,
      house: e.house,
      defaultLang: e.defaultLang
    }
    const email = e.email
    changeDataUser(email, data).then((res) => {
      if (res) enqueueSnackbar('Zmieniono dane poprawnie.', {variant: 'success'})
      else enqueueSnackbar('Nastąpił problem ze zmianą danych.', {variant: 'error'})
    })
  }
  const handleChangeData = () => {
    const user = LocalStorageHelper.get('user')
    return (
      <>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar>{user.email[0].toUpperCase()}</Avatar>
              <Typography sx={{ marginLeft: '20px', width: '100%' }}>
                {user.email}
              </Typography>
              <Button variant="contained" onClick={() => UserHelper.logout()}>
                Wyloguj
              </Button>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography sx={{ fontSize: '16px' }}>{t.profilePage.descriptionData}</Typography>
            <FormGenerator data={formData} submit={(e) => handleChangeDataSubmit(e)} {...props}/>
          </Grid>
        </Grid>
      </>
    )
  }
  const handleAccount = () => {
    return (
      <div>
        Zarządzanie kontem
      </div>
    )
  }
  const handleWishList = () => {
    return (
      <div>
        Lista ulubionych
      </div>
    )
  }
  const [dataTabs, setDataTabs] = useState([])
  useEffect(() => {
    const tabs = [
      {
        name: t.profilePage.changeData,
        tab: 'changeData',
        value: () => handleChangeData()
      },
      {
        name: 'Zarządzanie kontem',
        tab: 'manageAccount',
        value: () => handleAccount()
      },
      // {
      //   name: 'Lista ulubionych',
      //   tab: 'wishlist',
      //   value: () => handleWishList()
      // }
    ]
    if (LocalStorageHelper.get('user').role === 'admin') {
      tabs.push({
        name: 'Admin',
        tab: 'admin',
        value: () => (
          <Box>
            <Button onClick={() => history.push('/admin')} variant="contained">
              Administracja
            </Button>
          </Box>
        )
      })
    }
    setDataTabs(tabs)
  }, [])
  useEffect(() => {
    const pathname = window.location.pathname.search('deleteAccount')
    if (pathname > 0) {
      enqueueSnackbar(t.profilePage.toasts.success, {variant: 'success'})
    }
    return () => clearTimeout()
  }, [])
  return (
    <>
      <Seo data={{ 'seo: title': `${theme?.config?.seo?.company} - Panel administracyjny` }}/>
      <div style={style.root}>
        <Tabs data={dataTabs}/>
      </div>
    </>
  )
}

export default ProfilePage
