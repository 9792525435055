const Image = ({ alt, style, width, height, src, srcWebP, srcAvif, loading, srcSet, sizes = '(max-width: 600px) 100vw, 640px' }) => {
  return (
    <picture>
      {srcAvif && <source type="image/avif" srcSet={srcAvif} sizes={sizes} />}
      {srcWebP && <source type="image/webp" srcSet={srcWebP} sizes={sizes} />}
      <img
        loading={loading || 'eager'}
        alt={alt}
        decoding="async"
        fetchPriority={loading ? 'low' : 'high'}
        style={style}
        width={width || '100%'}
        height={height || 'auto'}
        src={src}
        srcSet={srcSet}
        sizes={sizes}
      />
    </picture>
  )
}

export default Image
