import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {DeleteOutlined} from '@mui/icons-material'
import useStore from '../../../store'

const SmallProduct = ({ minicart }) => {
  const removeFromCart = useStore((state) => state.removeFromCart)
  const style = {
    list: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flex: 1
    },
    product: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      border: '1px solid #f3f3f3',
      padding: '10px',
      width: 'calc(100% - 20px)',
      position: 'relative',
    },
    imageProduct: {
      maxWidth: '100%',
      width: 'auto',
      height: '40px',
      marginRight: '20px'
    },
    trash: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      cursor: 'pointer',
    }
  }
  return (
    <Box sx={style.list}>
      {
        minicart?.items?.map((data, index) => {
          return (
            <Box key={index} sx={style.product}>
              <img src={data?.image?.[0]} alt={data.name} style={style.imageProduct}/>
              <Box>
                <Typography sx={{marginBottom: '5px', fontWeight: '600', paddingRight: '25px' }}>{data?.name},
                  x {data?.quantity} szt.</Typography>
                <Typography>Cena: {(Number(data?.regularPrice) * 1.23).toFixed(2)} zł</Typography>
              </Box>
              <Box sx={style.trash} onClick={() => removeFromCart(data?.id)}>
                <DeleteOutlined />
              </Box>
            </Box>
          )
        })
      }
    </Box>
  )
}

export default SmallProduct
