import BasicTable from '../../atoms/Table/Table'

const Companies = () => {
  const rows = [
    {
      Firma: 'Fotograf Gajdamowicz',
      NIP: '6722018219',
      'Imię i nazwisko': 'Rafał Gajdamowicz',
      Email: 'studio@gajdamowicz.art',
      Telefon: '723 421 062',
      Adres: 'ul. Zwycięstwa 51/1, 75-020 Koszalin',
      'Strona internetowa': 'https://gajdamowicz.art/',
      Facebook: 'https://www.facebook.com/FotografKoszalinFotografGajdamowicz',
      Instagram: 'https://www.instagram.com/artgajdamowicz/',
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': 'https://drive.google.com/open?id=1p-iFmHUWlwrY2w9H1nYbdIDQWY8bRP6w&usp=drive_fs'
    },
    {
      Firma: '"Romart" Mariusz Romecki',
      NIP: '6691108558',
      'Imię i nazwisko': 'Mariusz Romecki',
      Email: 'biuroprofil@onet.eu',
      Telefon: '696024956',
      Adres: 'ul. Władysława Andersa 16a, 75-950 Koszalin',
      'Strona internetowa': 'https://apcamper.pl',
      Facebook: 'https://www.facebook.com/apcamperpolska',
      Instagram: null,
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': 'https://drive.google.com/open?id=1b6j2PQxOmnVpcV14tvnIAXgl_E8-D-r3&usp=drive_fs'
    },
    {
      Firma: 'Neeko',
      NIP: '5242495143',
      'Imię i nazwisko': 'Anna Biniek',
      Email: 'biuro@neeko.pl',
      Telefon: '538 984 984',
      Adres: 'ul. Dieselweg 20, 24159 Kiel',
      'Strona internetowa': 'https://sklep.neeko.pl/',
      Facebook: null,
      Instagram: null,
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': 'https://drive.google.com/open?id=1IRTh1fcD8HYr-G66pRBWhSzUX6CGS9Mf&usp=drive_fs'
    },
    {
      Firma: 'Complex Finance',
      NIP: '6692315354',
      'Imię i nazwisko': 'Agnieszka Bochnia',
      Email: 'biuro@complexfinance.pl',
      Telefon: '660 478 005',
      Adres: 'al. Monte Cassino 6/504, 75-412 Koszalin',
      'Strona internetowa': 'https://complexfinance.pl',
      Facebook: 'https://www.facebook.com/ComplexFinanceKoszalin',
      Instagram: 'https://www.youtube.com/channel/UCqcDDsboOibEwNHTocVJvGw',
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': 'https://drive.google.com/open?id=1RdeRCTyzReEQAywkeTxYCpSx3IlL4kt6&usp=drive_fs'
    },
    {
      Firma: 'Biuro Rachunkowe Dorota Cybulska',
      NIP: '4990017259',
      'Imię i nazwisko': 'Dorota Cybulska',
      Email: 'kontakt@ksiegowacybulska.pl',
      Telefon: '572 947 592',
      Adres: 'ul. Stanisława Wyspiańskiego 5A/3, 75-627 Koszalin',
      'Strona internetowa': 'https://ksiegowacybulska.pl/',
      Facebook: null,
      Instagram: null,
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': 'https://drive.google.com/open?id=1hS2Aw3gHfn92dY5EAvPdEz7KR9_6F_31&usp=drive_fs'
    },
    {
      Firma: 'SUKCESJE.PL',
      NIP: '6842089541',
      'Imię i nazwisko': 'Jakub Misiak',
      Email: 'jakub.misiak@sukcesje.pl',
      Telefon: '533488077',
      Adres: 'al. T. Rejtana 20/IIIP. 35-315 Rzeszów',
      'Strona internetowa': 'https://doradcy.sukcesje.pl/misiak-jakub/',
      Facebook: 'https://www.facebook.com/PanOdSukcesji/',
      Instagram: 'https://www.instagram.com/panodsukcesji/',
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': null
    },
    {
      Firma: 'Luxury Butique',
      NIP: '8531457475',
      'Imię i nazwisko': 'Paulina Jeżewska',
      Email: 'paulinajezewskaa@gmail.com',
      Telefon: '604111581',
      Adres: 'ul. Adama Mickiewicza 236, 74-200 Pyrzyce',
      'Strona internetowa': 'https://luxurybutique.pl/',
      Facebook: 'https://www.facebook.com/LuxuryButique937',
      Instagram: 'https://www.instagram.com/luxurybutique',
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': 'https://drive.google.com/open?id=1mCDvvRTdWeAGCVfrRiyoPpl_PC3HhcCC&usp=drive_fs'
    },
    {
      Firma: 'Butik A&M ',
      NIP: null,
      'Imię i nazwisko': 'Milena Zielińska',
      Email: 'milenazielinska92@gmail.com',
      Telefon: null,
      Adres: null,
      'Strona internetowa': 'Brak',
      Facebook: 'https://www.facebook.com/butikalicjaimilena',
      Instagram: 'Brak',
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': null
    },
    {
      Firma: 'Beanieventure',
      NIP: null,
      'Imię i nazwisko': 'Marta Ziemba',
      Email: 'ziemba.marta@gmail.com',
      Telefon: '782 167 201',
      Adres: 'ul. Wileńska 24, 48-304 Nysa',
      'Strona internetowa': null,
      Facebook: 'https://www.facebook.com/marta.mi.zrobila',
      Instagram: 'https://www.instagram.com/beanieventure/',
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': 'https://drive.google.com/open?id=1hqF3ZKfLDVmS--q3ss7tLHuOQahBwFPF&usp=drive_fs'
    },
    {
      Firma: 'AKM Finance i Ubezpieczenia',
      NIP: '7621959866',
      'Imię i nazwisko': 'Agnieszka Morawiak',
      Email: 'kontakt@akm-finanse.pl',
      Telefon: '722 392 983',
      Adres: 'ul. Cyprysowa 5a, 75-685 Koszalin',
      'Strona internetowa': 'https://akm-finanse.pl/',
      Facebook: 'https://www.facebook.com/akmfinanse',
      Instagram: null,
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': 'https://drive.google.com/open?id=1fLh3vEK4eCdB1io3ct7rwnv0J5Nppgce&usp=drive_fs'
    },
    {
      Firma: 'Osiedle Cyrkonii',
      NIP: '6692556885',
      'Imię i nazwisko': 'Mirek Strucki',
      Email: 'miroslawstrucki@gmail.com',
      Telefon: '604 424 300',
      Adres: 'ul. Jana Pawła II 20, 75-452 Koszalin',
      'Strona internetowa': 'https://osiedlecyrkonii.pl',
      Facebook: null,
      Instagram: null,
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': 'https://drive.google.com/open?id=1gdGW6XpoyY3H1Z1o6BNBKFFYcF2cX1YC&usp=drive_fs'
    },
    {
      Firma: 'Rehactive',
      NIP: '8513258400',
      'Imię i nazwisko': 'Dawid Besztak',
      Email: 'kontakt@rehactive.pl',
      Telefon: '530 547 289',
      Adres: 'ul. Parkowa 6, 71-600 Szczecin',
      'Strona internetowa': 'https://rehactive.com.pl',
      Facebook: null,
      Instagram: null,
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': 'https://drive.google.com/open?id=1If8jPDLl_BOYZt8t5GrYvQ_YL3NyT2da&usp=drive_fs'
    },
    {
      Firma: 'Kancelaria Prawna Katarzyna Jurkowska',
      NIP: '6692461558',
      'Imię i nazwisko': 'Katarzyna Jurkowska',
      Email: 'blachuta.katarzyna@gmail.com',
      Telefon: '509 996 512',
      Adres: 'ul. Daszyńskiego 52, 71-664 Szczecin',
      'Strona internetowa': null,
      Facebook: null,
      Instagram: null,
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': 'https://drive.google.com/open?id=1Geqa3UaeSttrdbfgfrA9NsqkxKYLOnWR&usp=drive_fs'
    },
    {
      Firma: 'VTS',
      NIP: 'CVR 43191985',
      'Imię i nazwisko': 'David Andrade',
      Email: 'davidfredrickandrade@gmail.com',
      Telefon: null,
      Adres: 'ul. Avedøreholmen 82, 2650, Hvidovre, DK',
      'Strona internetowa': 'https://vts.one',
      Facebook: null,
      Instagram: null,
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': 'https://drive.google.com/open?id=19tnS-aVEZmUEVL7DL8nMANZ0uD-jcpVc&usp=drive_fs'
    },
    {
      Firma: 'Studio Kade',
      NIP: null,
      'Imię i nazwisko': 'Karolina Foryś',
      Email: 'karolinadziewiecka@gmail.com',
      Telefon: '669 422 100',
      Adres: null,
      'Strona internetowa': 'https://studio-kade.pl',
      Facebook: null,
      Instagram: null,
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': 'https://drive.google.com/open?id=19LZe2jizNNgxnBn4hjpZ9fiuhvt8G6xm&usp=drive_fs'
    },
    {
      Firma: 'Szkoła Podstawowa w Manowie',
      NIP: '4990666992',
      'Imię i nazwisko': 'Anna Wiśniowska',
      Email: 'anwisniowska1987@gmail.com',
      Telefon: '793 904 594',
      Adres: 'ul. Szkolna 12, 76-015 Manowo',
      'Strona internetowa': null,
      Facebook: null,
      Instagram: null,
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': null
    },
    {
      Firma: 'Adeszkolenia Kasprzak & Rachlewicz Sp. z o.o.',
      NIP: '9542772519',
      'Imię i nazwisko': 'Ewa Kasprzak',
      Email: 'biuro@adeszkolenia.com',
      Telefon: '731 479 688',
      Adres: 'ul. Krasińskiego 29, Katowice',
      'Strona internetowa': 'https://adeszkolenia.com',
      Facebook: 'https://www.facebook.com/profile.php?id=100000330435434',
      Instagram: null,
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': null
    },
    {
      Firma: 'Szkoła Języków Fabryka Angelika',
      NIP: '5961722719',
      'Imię i nazwisko': 'Agnieszka Bernaś',
      Email: 'fabryka.anglika@gmail.com',
      Telefon: '531 881 718',
      Adres: 'Gdańśk',
      'Strona internetowa': null,
      Facebook: 'https://www.facebook.com/fabryka.anglika',
      Instagram: null,
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': 'https://drive.google.com/open?id=1m1X1_leGs1gPFIRAl-PGjkku2ZB0-vWd&usp=drive_fs'
    },
    {
      Firma: 'Montessori w praktyce',
      NIP: null,
      'Imię i nazwisko': 'Anna Wojciechowska',
      Email: 'montessori.magnolia@gmail.com',
      Telefon: '516 280 462',
      Adres: null,
      'Strona internetowa': 'https://montessoriwpraktyce.pl',
      Facebook: 'https://www.facebook.com/groups/259171048340206',
      Instagram: null,
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': 'https://drive.google.com/open?id=1m3UkPugNdFHGIxvQlUfMgwUes3lN5KiB&usp=drive_fs'
    },
    {
      Firma: 'Cafe Mondo',
      NIP: '6692534174',
      'Imię i nazwisko': 'Małgorzata Przekurat',
      Email: 'malgorzata.przekurat@icloud.com',
      Telefon: '601 658 404',
      Adres: 'Rynek Staromiejski 13, 75-007 Koszalin',
      'Strona internetowa': 'https://cafemondo.pl',
      Facebook: 'https://www.facebook.com/cafemondorynekstaromiejski',
      Instagram: null,
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': 'https://drive.google.com/open?id=1VMWRVrrISiZVQ_moCws4kuV-Ny-FsrkU&usp=drive_fs'
    },
    {
      Firma: 'Sanrusen',
      NIP: '1341800900',
      'Imię i nazwisko': 'Arkadiusz Leszko',
      Email: 'a.leszko@gmx.de',
      Telefon: '604 527 332',
      Adres: 'Kantstr 107 D-10627, Berlin, DE',
      'Strona internetowa': 'https://arbeitnehmerpolen.com',
      Facebook: null,
      Instagram: null,
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': 'https://drive.google.com/open?id=18yemOpDGOXSFHVUAsWY-SfwZ2KoKVHyH&usp=drive_fs'
    },
    {
      Firma: 'Granimar',
      NIP: '8511883884',
      'Imię i nazwisko': 'Grzegorz Jan Kępisty',
      Email: 'biuro@granimar.pl',
      Telefon: '503 817 200',
      Adres: 'ul. Modra 40, 71-220 Szczecin',
      'Strona internetowa': 'https://granimar.pl',
      Facebook: null,
      Instagram: null,
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': 'https://drive.google.com/open?id=1z9Erdu9TYZvbJf8DLNHMCBXxw1kbM6G7&usp=drive_fs'
    },
    {
      Firma: 'SML Szkoła Języków Obcych',
      NIP: '6692569617',
      'Imię i nazwisko': 'Mirek Strucki',
      Email: 'info@sml.edu.pl',
      Telefon: '604 424 300',
      Adres: 'ul. Jana Pawła II 20, 75-452 Koszalin',
      'Strona internetowa': 'https://www.sml.edu.pl',
      Facebook: 'https://www.facebook.com/SzkolaJezykowObcychSML',
      Instagram: null,
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': 'https://drive.google.com/open?id=1z9Erdu9TYZvbJf8DLNHMCBXxw1kbM6G7&usp=drive_fs'
    },
    {
      Firma: 'Robocreator Sp. z o.o.',
      NIP: '7822619344',
      'Imię i nazwisko': 'Monika Kirol',
      Email: 'm.kirol@twojrobot.pl',
      Telefon: '882 775 479',
      Adres: 'ul. Głogowska 31/33, 60-702 Poznań',
      'Strona internetowa': 'http://www.twojrobot.pl',
      Facebook: 'https://www.facebook.com/LEMOrobot',
      Instagram: null,
      LinkedIn: null,
      'Numer konta': null,
      'Dysk Google': null
    }
  ]

  return (
    <div>
      <BasicTable disabledEdit data={rows} callback={() => console.log('asd')}/>
    </div>
  )
}

export default Companies
