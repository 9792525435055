import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {useHistory} from 'react-router'

const Product = ({ product }) => {
  const history = useHistory()
  const data = product?.data
  const style = {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'space-between',
      justifyContent: 'space-around',
      marginTop: '20px',
      cursor: 'pointer',
      position: 'relative',
      border: '1px solid #d5d5d5',
      padding: '15px',
      height: '250px',
    },
    img: {
      width: 'auto',
      maxWidth: '100%',
      height: '100px',
      margin: '0 auto',
      fitObject: 'contain',
    },
    title: {
      marginTop: '20px',
      textAlign: 'center',
      fontSize: '16px',
    }
  }
  const handleClick = () => history.push(`/product/${data?.id}`)
  return (
    <Box sx={style.root} onClick={() => handleClick()}>
      <img src={data?.image} alt={data?.name} style={style.img} />
      <Typography variant="h6" color="primary" component="h2" sx={style.title}>{data?.name}</Typography>
      <Box dangerouslySetInnerHTML={{ __html: `${data?.description.slice(0, 80)}...` }} sx={{ color: '#857e7e' }} />
      <Typography variant="body1" color="primary" component="p">
        {Number(data?.regularPrice * 1.23)?.toFixed(2)} zł brutto
        <Typography variant="body2" color="textSecondary" component="span"> ({Number(data?.regularPrice)?.toFixed(2)} zł netto)</Typography>
      </Typography>
    </Box>
  )
}

export default Product
