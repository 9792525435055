import NavigationPreview from '../../components/molecules/NavigationPreview/NavigationPreview'
import MagicLoader from '../../components/atoms/MagicLoader/MagicLoader'
import Footer from '../../components/molecules/Footer/Footer'

const PreviewLayout = (props) => {
  const Component = props.component
  const {location} = props
  const style = {
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'start',
      flexDirection: 'column',
      padding: '80px 0 0 0',
      width: '100%',
      margin: '0 auto',
      maxWidth: '1920px'
    }
  }
  return (
    <MagicLoader>
      <div style={style.root}>
        <NavigationPreview location={location}/>
        <Component location={location}/>
        <Footer/>
      </div>
    </MagicLoader>
  )
}

export default PreviewLayout
