import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {useHistory} from 'react-router'
import useStore from '../../store'
import {useEffect, useState} from 'react'
import {Grid, useTheme} from '@mui/material'
import Seo from '../../components/molecules/Seo/Seo'
import SizeWindow from '../../components/atoms/SizeWindow/SizeWindow'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import SmallProduct from '../../components/molecules/SmallProduct/SmallProduct'
import Inpost from '../../components/organisms/Inpost/Inpost'

const CartPage = () => {
  const history = useHistory()
  const theme = useTheme()
  const seo = theme.config.seo
  const minicart = useStore((state) => state.miniCart)
  const setStep = useStore((state) => state.setStep)
  const [price, setPrice] = useState(0)
  const [methodData, setMethodData] = useState(null)
  const formData = {
    elements: [
      {
        name: 'method',
        type: 'radio',
        items: [
          {
            value: 'online',
            label: 'Usługa online - bezpłatna'
          },
          // {
          //   value: 'inpost',
          //   label: 'Inpost - paczkomaty 13,99 zł',
          // },
          // {
          //   value: 'cod',
          //   label: 'Odbiór własny - bezpłatna',
          // },
        ],
        label: 'Usługa online'
      },
      {
        type: 'button',
        value: 'Wybierz metodę dostawy'
      }
    ]
  }
  const getAllPrices = () => {
    let tempPrice = 0
    minicart?.items?.forEach((item) => {
      tempPrice += Number(item.regularPrice)
    })
    setPrice(tempPrice)
  }
  const handleCheckout = () => history.push('/checkout')
  const style = {
    title: {
      marginBottom: '20px'
    },
  }
  const createMethodForm = (e) => {
    LocalStorageHelper.set('cartInfo', {
      method: e.method
    })
    setMethodData(e)
  }
  useEffect(() => {
    if (minicart) {
      getAllPrices()
    }
  }, [minicart])
  useEffect(() => {
    LocalStorageHelper.remove('cartInfo')
    setStep(1)
  }, [])
  return (
    <SizeWindow>
      <Seo data={{'seo: title': `${seo.company} - Koszyk`}}/>
      <Typography variant="h5" sx={style.title}>Koszyk</Typography>
      <Grid container spacing={8}>
        <Grid item md={8} xs={12}>
          {
            minicart?.items?.length > 0 ? (
              <>
                <SmallProduct minicart={minicart} />
                <Typography sx={{ fontWeight: '600', margin: '20px 0' }}>Wybierz metodę dostawy</Typography>
                {/*<Inpost />*/}
                <FormGenerator data={formData} submit={(e) => createMethodForm(e)} />
              </>
            ) : (
              <Typography sx={{ margin: '20px 0' }}>Brak produktów w koszyku.</Typography>
            )
          }
        </Grid>
        {
          minicart?.items?.length > 0 && (
            <Grid item md={3} xs={12}>
              <Box sx={{width: '100%'}}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography>Podsumowanie:</Typography>
                  <Typography>{(Number(price) * 1.23).toFixed(2)} zl brutto</Typography>
                </Box>
                <Typography sx={{marginBottom: '20px', textAlign: 'right', marginTop: '10px'}}>{Number(price).toFixed(2)} zl
                  netto</Typography>
                <Button fullWidth variant={'contained'} sx={{height: '40px'}} onClick={() => handleCheckout()} disabled={!methodData}>
                  Przejdź do zamówienia
                </Button>
              </Box>
            </Grid>
          )
        }
      </Grid>
    </SizeWindow>
  )
}

export default CartPage
