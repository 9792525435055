import ReactDOM from 'react-dom/client'
import App from './App'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import ToastProvider from './components/organisms/ToastProvider/ToastProvider'

const root = ReactDOM.createRoot(document.getElementById('root'))

const error = console.error
console.error = (...args) => {
  if (/defaultProps/.test(args[0])) return
  error(...args)
}

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#2c296a',
      black: '#000000',
      white: '#ffffff'
    },
    secondary: {
      main: '#ffffff',
      second: '#2c296a',
      grey: '#B5B5B5',
      greyLight: '#EBEBEB',
      greyDark: '#767373'
    },
    toasts: {
      success: '#2e7d32',
      warning: '#ed6c02',
      error: '#d32f2f',
      info: '#0288d1'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  typography: {
    useNextVariants: true
  },
  config: {
    maxToasts: 5,
    show: {
      gallery: false,
    },
    component: {
      description: '',
    },
    seo: {
      author: 'Your site, contact@your-site.pl',
      url: 'https://your-site.pl',
      website: 'your-site.pl',
      keywords: 'aplikacje webowe,strony internetowe,grafika komputerowa',
      smallDescription: 'Your site to dynamiczna firma specjalizująca się w tworzeniu nowoczesnego i innowacyjnego oprogramowania, w tym aplikacji webowych.',
      bigDescription: 'Your site to dynamiczna firma specjalizująca się w tworzeniu nowoczesnego i innowacyjnego oprogramowania, w tym aplikacji webowych, które odpowiadają na potrzeby współczesnego rynku. Nasz zespół składa się z doświadczonych specjalistów, którzy łączą wiedzę techniczną z kreatywnością, co pozwala nam dostarczać rozwiązania dostosowane do specyficznych wymagań naszych klientów. Realizujemy projekty oparte na najnowszych technologiach, zapewniając wysoką jakość, niezawodność i skalowalność produktów. Stawiamy na bliską współpracę z klientami, co pozwala nam lepiej zrozumieć ich potrzeby i dostarczać rozwiązania, które wspierają ich rozwój oraz sukces na rynku. Nasze aplikacje webowe cechują się intuicyjnością, bezpieczeństwem oraz optymalizacją pod kątem wydajności, co czyni je skutecznymi narzędziami w cyfrowej transformacji firm.',
      name: 'Jan Szymański',
      email: 'contact@your-site.pl',
      phone: '+48 509 609 439',
      company: 'Your site',
      address: 'ul. Holenderska 57/22, 75-430 Koszalin',
      postalCode: '75-430',
      city: 'Koszalin',
      region: 'Zachodniopomorskie',
      country: 'Polska',
      facebook: 'https://www.facebook.com/yourSitePoland',
      youtube: '',
      instagram: '',
      hours: {
        ponPt: '07:00 - 24:00',
        sob: '07:00 - 24:00',
        nd: 'Nieczynne'
      },
      nip: '8522589668',
      regon: '527077983',
      googleMaps: 'https://www.google.com/maps/place/Your+site/@54.2171823,16.1739924,17z/data=!3m1!4b1!4m6!3m5!1s0x4701cd1270015669:0x71f0bb78ebf4789c!8m2!3d54.2171823!4d16.1765727!16s%2Fg%2F11pbymrtmm?entry=ttu',
      ceidg: 'https://aplikacja.ceidg.gov.pl/ceidg/ceidg.public.ui/SearchDetails.aspx?Id=e0d1d667-a6b0-4f45-b19a-ff4ebb16b971',
      pixel: 'yourSitePoland',
    },
  }
})

// eslint-disable-next-line jest/require-hook
root.render(
  <ToastProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <App/>
    </ThemeProvider>
  </ToastProvider>
)
