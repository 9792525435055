import {Grid, Link, useTheme} from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Seo from '../../components/molecules/Seo/Seo'
import Page from '../../components/molecules/Page/Page'
import ContactImage from '../../assets/images/kontakt.jpg'

const ContactPage = () => {
  const theme = useTheme()
  const seo = theme.config.seo
  const { phone, email, address, hours, nip, regon } = seo
  return (
    <>
      <Seo data={{'seo: title': `${seo.company} - Kontakt`}}/>
      <Page
        image={ContactImage}
        title={'Kontakt'}
        small={'Masz pytania, napisz lub zadzwoń'}
        description={(
          <>
            <Grid container spacing={4}>
              <Grid item md={12} xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h3" sx={{ fontSize: '24px', fontWeight: '600', marginBottom: '10px' }}>Przyjedź</Typography>
                  <Link href={`https://www.google.com/maps/place/${address}/@54.1953834,16.1894642,17z/data=!3m1!4b1!4m5!3m4!1s0x4701cd13bbf74b71:0x5b45fac2e58fc705!8m2!3d54.1953803!4d16.1920445?entry=ttu`}>Adres: {address}</Link>
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h3" sx={{ fontSize: '24px', fontWeight: '600', marginBottom: '10px' }}>Napisz lub Zadzwoń</Typography>
                  <Link sx={{ marginBottom: '10px' }} href={`mailto:${email}`}>Email: {email}</Link>
                  <Link href={`tel:${phone?.replace(' ', '')?.replace(' ', '')?.replace(' ', '')}`}>Telefon: {phone}</Link>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h3" sx={{ fontSize: '24px', fontWeight: '600', marginBottom: '10px' }}>Godziny otwarcia</Typography>
                  <Typography variant="p"><b>Pon - Pt</b> {hours.ponPt}</Typography>
                  <Typography variant="p"><b>Sobota</b> {hours.sob}</Typography>
                  <Typography variant="p"><b>Niedziela</b> {hours.nd}</Typography>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h3" sx={{ fontSize: '24px', fontWeight: '600', marginBottom: '10px' }}>Dane szczegółowe</Typography>
                  <Typography variant="p"><b>NIP</b> {nip}</Typography>
                  <Typography variant="p"><b>REGON</b> {regon}</Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      />
    </>
  )
}

export default ContactPage
