import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {useTheme} from '@mui/styles'
import {Grid, IconButton, Link} from '@mui/material'
import FormGenerator from '../../organisms/FormGenerator/FormGenerator'
import Dot from '../../atoms/Dot/Dot'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import ApiHelper from '../../../helpers/ApiHelper/ApiHelper'
import {useSnackbar} from 'notistack'
import { ContentCopy } from '@mui/icons-material'

const ContactForm = () => {
  const theme = useTheme()
  const {enqueueSnackbar} = useSnackbar()
  const { phone, email, address, hours, nip, regon, ceidg, googleMaps } = theme.config.seo
  const style = {
    root: {
      background: theme.palette.secondary.second,
      padding: BreakpointsHelper.isMobile() ? '30px' : '50px',
      display: 'flex',
      minHeight: '500px',
      borderRadius: '3px',
      marginBottom: '50px',
      flexDirection: 'column',
      marginTop: '50px',
    },
    h2: {
      fontSize: BreakpointsHelper.isMobile() ? '38px' : '52px',
      fontWeight: '600',
      textAlign: 'left',
      width: '100%',
      color: 'white'
    },
    h3: {
      fontSize: '18px',
      fontWeight: '400',
      marginTop: '10px',
      textAlign: 'left',
      width: '100%',
      color: 'white'
    },
    button: {
      marginTop: '20px',
      padding: '6px 16px',
      borderRadius: '6px',
      minHeight: '31px',
      cursor: 'pointer',
      textTransform: 'uppercase',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonActive: {
      background: '#324b54',
      color: 'white',
      marginTop: '20px',
      padding: '6px 16px',
      borderRadius: '6px',
      minHeight: '31px',
      cursor: 'pointer',
      textTransform: 'uppercase',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    articleImageActive: {
      width: '100%',
      marginTop: '20px',
      borderRadius: '20px'
    },
    span: {
      color: 'white',
      fontWeight: 200,
      cursor: 'pointer',
      marginBottom: '8px',
    }
  }
  const formData = {
    elements: [
      {
        name: 'name',
        label: 'Imię i nazwisko / Firma',
        type: 'text',
      },
      {
        name: 'phone',
        label: 'Telefon',
        type: 'text',
      },
      {
        name: 'email',
        label: 'Email',
        type: 'text',
      },
      {
        name: 'message',
        label: 'Wiadomość',
        type: 'textarea',
      },
      {
        type: 'button',
        value: 'Wyślij'
      }
    ]
  }
  const handleCopy = (text) => navigator.clipboard.writeText(text).then(() => {})
  const handleSubmit = (e) => {
    const data = {
      mail_to: email,
      name: e?.name,
      phone: e?.phone,
      email: e?.email,
      subject: email,
      message: e?.message,
      company: theme.config.seo.company,
    }
    ApiHelper.post('https://api.your-site.pl/email', data).then(() => {
        enqueueSnackbar('Dane wysłano poprawnie.', {variant: 'success'})
    })
  }
  return (
    <Box sx={style.root}>
      <Box sx={{ marginTop: '20px' }}>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <Typography variant="h2" sx={style.h2}>
              Skontaktuj się ze mną<Dot background={'white'}/>
            </Typography>
            <Typography variant="h3" sx={style.h3}>
              Zadzwoń, napisz email lub wypełnij formularz
            </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
                <Typography sx={{ fontSize: '24px', fontWeight: '600', marginBottom: '10px', color: 'white' }}>Dane kontaktowe</Typography>
                <Link href={phone ? `tel:${phone}` : '#'} sx={style.span}><b>Telefon</b>: {phone}</Link>
                <Link href={email ? `mailto:${email}` : '#'} sx={style.span}><b>Email</b>: {email}</Link>
                <Link href={googleMaps || '#'} sx={style.span}><b>Adres</b>: {address}</Link>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '30px' }}>
                <Typography sx={{ fontSize: '24px', fontWeight: '600', marginBottom: '10px', color: 'white' }}>Godziny otwarcia</Typography>
                <Typography sx={style.span}><b>Pon - Pt</b>: {hours.ponPt}</Typography>
                <Typography sx={style.span}><b>Sobota</b>: {hours.sob}</Typography>
                <Typography sx={style.span}><b>Niedziela</b>: {hours.nd}</Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '30px' }}>
                <Typography sx={{ fontSize: '24px', fontWeight: '600', marginBottom: '10px', color: 'white' }}>Dane dodatkowe</Typography>
                <Typography sx={style.span}>
                  <b>NIP</b>: {nip}
                  <IconButton onClick={() => handleCopy(nip)} size="small">
                    <ContentCopy sx={{ color: 'white', marginLeft: '10px' }} fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography sx={style.span}>
                  <b>REGON</b>: {regon}
                  <IconButton onClick={() => handleCopy(regon)} size="small">
                    <ContentCopy sx={{ color: 'white', marginLeft: '10px' }} fontSize="small" />
                  </IconButton>
                </Typography>
                <Link href={ceidg} target="_blank" sx={style.span}><b>CEIDG</b>: Centralna Ewidencja i Informacja o Działalności Gospodarczej</Link>
              </Box>
          </Grid>
          <Grid item md={5} xs={12}>
            <Box sx={{ background: 'white', borderRadius: '3px', padding: '30px' }}>
              <FormGenerator data={formData} submit={(e) => handleSubmit(e)} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ContactForm
