import {useParams} from 'react-router-dom'
import Typography from '@mui/material/Typography'
import SizeWindow from '../../components/atoms/SizeWindow/SizeWindow'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {useHistory} from 'react-router'
import useStore from '../../store'
import {useEffect} from 'react'

const SuccessPage = () => {
  const history = useHistory()
  const clearCart = useStore((state) => state.clearCart)
  const { id } = useParams()
  const style = {
    box: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '720px',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '100px auto',
      textAlign: 'center',
    },
    button: {
      marginTop: '20px',
    }
  }
  useEffect(() => {
    clearCart()
  }, [])
  return (
    <SizeWindow>
      <Box sx={style.box}>
        <Typography variant="h1">Gratulujemy</Typography>
        <Typography variant="h4" color="textSecondary">Zamówienie {id} zostało pomyślnie zrealizowane.</Typography>
        <Button variant="contained" sx={style.button} onClick={() => history.push('/')}>
          Powrót do strony głównej
        </Button>
      </Box>
    </SizeWindow>
  )
}

export default SuccessPage
