import Seo from '../../components/molecules/Seo/Seo'
import {Grid} from '@mui/material'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import {getAllDataFromPath, updateOrCreateDocument} from '../../firebase'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import MagicLoader from '../../components/atoms/MagicLoader/MagicLoader'
import SeoHelper from '../../helpers/SeoHelper/SeoHelper'
import {useTheme} from '@mui/styles'

const EditArticlePage = () => {
    const theme = useTheme()
    const idElement = window.location.pathname.split('/')[2]
    const {enqueueSnackbar} = useSnackbar()
    const [silos, setSilos] = useState(null)
    const [checkSilos, setCheckSilos] = useState(null)
    const [categories, setCategories] = useState(null)
    const [tags, setTags] = useState(null)
    const [checkCategories, setCheckCategories] = useState([])
    const [itemsTagsTournaments, setItemsTagsTournaments] = useState(null)
    const [silosItems, setSilosItems] = useState(null)
    const style = {
        root: {
            width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
            padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
        },
        arrayNotExist: {
            fontSize: '16px',
            marginTop: '20px',
            color: '#324b54',
            background: '#ededed',
            padding: '20px',
            width: 'auto',
            borderRadius: '50px',
        }
    }
    const [formDataSilos, setFormDataSilos] = useState({})
    const handleSubmit = (e) => {
        let data = null
        if (e.image) {
            data = { ...e }
        } else {
            const { image, ...rest } = e
            data = { ...rest }
        }
        console.log(data)
        updateOrCreateDocument('articles', idElement, data).then((res) => {
            if (res) {
                enqueueSnackbar('Zapisano dane poprawnie.', {variant: 'success'})
            }
        })
    }
    const updateSilos = () => {
        getAllDataFromPath('silos').then((res) => {
            if (res) {
                setSilos(res)
            }
        })
    }
    const updateCategories = () => {
        getAllDataFromPath('categories').then((res) => {
            if (res) {
                res.sort((a, b) => {
                    const fa = a?.data?.silos?.toLowerCase()
                    const fb = b?.data?.silos?.toLowerCase()
                    if (fa < fb) return -1
                    if (fa > fb) return 1
                    return 0
                })
                setCategories(res)
            }
        })
    }
    const updateTags = () => {
        getAllDataFromPath('tags').then((res) => {
            if (res) {
                res.sort((a, b) => {
                    const fa = a?.data?.silos?.toLowerCase()
                    const fb = b?.data?.silos?.toLowerCase()
                    if (fa < fb) return -1
                    if (fa > fb) return 1
                    return 0
                })
                const tempItems = []
                res?.forEach(el => {
                    tempItems.push({ name: el?.data?.name, value: el?.data?.name })
                })
                setItemsTagsTournaments(tempItems)
                setTags(res)
            }
        })
    }
    useEffect(() => {
            getAllDataFromPath('articles').then((res) => {
                if (res) {
                    res?.forEach(el => {
                        if (el?.id === idElement) {
                            const dataSeoTemp = {}
                            dataSeoTemp['seo: category'] = el?.data['seo: category'] || ''
                            dataSeoTemp['seo: description'] = el?.data['seo: description'] || ''
                            dataSeoTemp['seo: title'] = el?.data['seo: title'] || ''
                            dataSeoTemp['seo: fbPageId'] = el?.data['seo: fbPageId'] || ''
                            dataSeoTemp['seo: keywords'] = el?.data['seo: keywords'] || ''
                            dataSeoTemp['seo: ogDescription'] = el?.data['seo: ogDescription'] || ''
                            dataSeoTemp['seo: ogImage'] = el?.data['seo: ogImage'] || ''
                            dataSeoTemp['seo: ogTitle'] = el?.data['seo: ogTitle'] || ''
                            dataSeoTemp['seo: page'] = el?.data['seo: page'] || ''
                            dataSeoTemp['seo: prase'] = el?.data['seo: prase'] || ''
                            dataSeoTemp['seo: twitterDescription'] = el?.data['seo: twitterDescription'] || ''
                            dataSeoTemp['seo: twitterId'] = el?.data['seo: twitterId'] || ''
                            dataSeoTemp['seo: twitterImage'] = el?.data['seo: twitterImage'] || ''
                            dataSeoTemp['seo: twitterTitle'] = el?.data['seo: twitterTitle'] || ''
                            const dataSeo = {
                                data: dataSeoTemp
                            }
                            const checkTags = []
                            tags?.forEach((el) => {
                                if (el?.data?.silos === checkSilos) {
                                    checkTags.push({ value: el.id, name: el.data.name })
                                }
                            })
                            setCheckSilos(el?.data?.silos)
                            const formDataArticles = {
                                elements: [
                                    {
                                        name: 'silos',
                                        type: 'select',
                                        label: 'Wybierz silos, z którego artykuł pochodzi',
                                        items: silosItems,
                                        value: el?.data?.silos,
                                        onChange: (e) => setCheckSilos(e),
                                    },
                                    {
                                        name: 'category',
                                        label: 'Wybierz kategorie artykułu',
                                        type: 'select',
                                        value: el?.data?.category,
                                        items: checkCategories,
                                    },
                                ],
                            }
                            formDataArticles.elements.push({
                                name: 'tags',
                                label: 'Wybierz tagi artykułu',
                                type: 'multiSelect',
                                defaultValue: el?.data?.tags?.map(el => el.value),
                                items: checkTags,
                            })
                            formDataArticles.elements.push({
                                  name: 'title',
                                  label: 'Tytuł artykułu',
                                  value: el?.data?.title,
                                  type: 'text',
                              },
                              {
                                  name: 'description',
                                  label: 'Opis artykułu',
                                  value: el?.data?.description,
                                  type: 'editor',
                              })
                            formDataArticles.elements.push({
                                name: 'image',
                                label: 'Zdjęcie główne',
                                value: el?.data?.image,
                                type: 'files',
                                pathname: 'files',
                            })
                            SeoHelper.addFieldSeo(formDataArticles, [dataSeo])
                            formDataArticles.elements.push({ type: 'button', value: 'Zapisz kategorię' })
                            setFormDataSilos(formDataArticles)
                        }
                    })
                }
            })
    }, [silosItems, tags, checkSilos, checkCategories])
    useEffect(() => {
        if (silos?.length > 0) {
            const temp = []
            silos.forEach(el => {
                const data = el?.data
                temp.push({ value: data?.name, name: data?.name })
            })
            setSilosItems(temp)
        }
    }, [silos])
    useEffect(() => {
        if (checkSilos && categories) {
            const tempCat = []
            categories?.forEach((el) => {
                if (el?.data?.silos === checkSilos) {
                    tempCat.push({ value: el.id, name: el.data.name })
                }
            })
            if (tempCat?.length > 0) {
                setCheckCategories(tempCat)
            }
        }
    }, [checkSilos, categories])
    useEffect(() => {
        updateSilos()
        updateTags()
        updateCategories()
    }, [])
    return (
        <>
            <Seo data={{'seo: title': `${theme?.config?.seo?.company} - Panel administracyjny`}}/>
            <MagicLoader time={1500} variable={silos && silosItems && formDataSilos?.elements?.length > 0}>
                <div style={style.root}>
                    <Grid container spacing={8}>
                        <Grid item md={6} xs={12}>
                            <FormGenerator data={formDataSilos} submit={(e) => handleSubmit(e)}/>
                        </Grid>
                    </Grid>
                </div>
            </MagicLoader>
        </>
    )
}

export default EditArticlePage
