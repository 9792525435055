import {useEffect, useState} from 'react'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import logo from '../../../assets/images/logo.png'
import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu'
import {Badge, Drawer, Menu, MenuItem} from '@mui/material'
import Button from '@mui/material/Button'
import {getAllDataFromPath} from '../../../firebase'
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state'
import {AccountCircleOutlined, FavoriteOutlined, SearchOutlined, ShoppingCartOutlined} from '@mui/icons-material'
import {useHistory} from 'react-router'
import Minicart from '../../atoms/Minicart/Minicart'
import useStore from '../../../store'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import Typography from '@mui/material/Typography'

const NavigationPreview = () => {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [silos, setSilos] = useState(null)
  const [categories, setCategories] = useState(null)
  const miniCart = useStore((state) => state.miniCart)
  const setMinicart = useStore((state) => state.setMiniCart)
  const [minicartOpen, setMinicartOpen] = useState(false)
  const [countCart, setCountCart] = useState(0)
  const user = LocalStorageHelper.get('user')
  const style = {
    nav: {
      position: 'fixed',
      top: 0,
      right: 0,
      width: '100%',
      height: '80px',
      background: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      zIndex: 999,
      boxShadow: '2px 2px 20px 10px rgba(0, 0, 0, 0.02)',
      '& > div': {
        maxWidth: '1920px'
      }
    },
    logo: {
      maxWidth: '320px',
      height: '55px',
      cursor: 'pointer',
      marginRight: '20px',
    },
    menu: {
      width: 30,
      height: 30,
      color: '#324b54',
      cursor: 'pointer',
      paddingRight: '30px'
    },
    logoOpen: {
      height: BreakpointsHelper.isMobile() ? '55px' : '100%',
      marginLeft: BreakpointsHelper.isMobile() ? '0' : '35px',
      position: 'relative',
      left: '-5px',
      bottom: '-3px'
    },
    buttons: {
      display: 'flex',
      alignItems: 'center'
    },
    burgerOpen: {
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      position: 'relative',
      right: '-5px',
      bottom: '-2px',
      marginBottom: '30px'
    },
    elementNav: {
      fontSize: '18px',
      marginLeft: BreakpointsHelper.isMobile() ? '0' : '40px',
      cursor: 'pointer',
      border: BreakpointsHelper.isMobile() && '1px solid black',
      padding: BreakpointsHelper.isMobile() && '20px',
      textAlign: BreakpointsHelper.isMobile() && 'center',
      marginBottom: BreakpointsHelper.isMobile() && '20px'
    },
    menuRight: {
      display: 'flex',
      alignItems: 'center',
    }
  }
  const openLink = (link) => history.push(link)
  const generateMenu = () => {
    const pages = [
      {
        name: 'Home',
        link: '/home'
      },
      {
        name: 'Sklep',
        link: '/shop'
      },
      {
        name: 'O firmie',
        link: '/about'
      },
      {
        name: 'Portfolio',
        link: '/portfolio'
      },
    ]
    silos?.forEach(el => {
      const data = el?.data
      if (data?.silos === 'menu') {
        const menu = {
          name: data?.name,
          link: '/',
          ul: []
        }
        categories?.forEach(elCat => {
          const dataCat = elCat?.data
          if (dataCat?.silos === data?.name) {
            menu.ul.push({
              name: dataCat?.name,
              link: `/showCategories/${elCat?.id}`
            })
          }
        })
        if (menu?.ul?.length > 0) {
          pages.push(menu)
        }
      }
    })
    const blog = {
      name: 'Blog',
      link: '/',
      ul: []
    }
    silos?.forEach(el => {
      const data = el?.data
      if (data?.silos === 'blog') {
        blog?.ul?.push({
          name: data?.name,
          link: `/showCategories/${el?.id}`
        })
      }
    })
    if (blog?.ul?.length > 0) {
      pages.push(blog)
    }
    pages.push({
      name: 'Kontakt',
      link: '/contact'
    })
    return (
      <header style={{display: 'flex'}}>
        <Box sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: BreakpointsHelper.isMobile() ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          {
            pages.map((page, index) => {
              if (page?.ul?.length > 0) {
                return (
                  <PopupState key={`page-${index}`} variant="popover" popupId={`popup-menu-${index}`}>
                    {(popupState) => (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: BreakpointsHelper.isMobile() ? 'column' : 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Button
                          sx={{
                            fontSize: '14px',
                            color: '#324b54 !important',
                            paddingBottom: '10px',
                            borderRadius: 0,
                            borderBottom: window.location.pathname === page.link ? '1px solid #324b54' : 'none',
                            marginLeft: '8px'
                          }}
                          variant={index + 1 === pages?.length ? 'contained' : 'text'}
                          {...bindTrigger(popupState)}
                        >
                          {page?.name || ''}
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                          {
                            page?.ul?.map((element, key) => (
                              <MenuItem
                                key={`item-${index}-${key}`}
                                sx={{ textTransform: 'uppercase', fontWeight: 300 }}
                                onClick={() => {
                                  openLink(element.link)
                                  popupState.close()
                                }}
                              >
                                {element.name}
                              </MenuItem>
                            ))
                          }
                        </Menu>
                      </Box>
                    )}
                  </PopupState>
                )
              }
              return (
                <Button
                  key={`button-${index}`}
                  onClick={() => openLink(page.link)}
                  variant="text"
                  className={window.location.pathname === page.link && 'active'}
                  sx={{
                    fontSize: '14px',
                    marginLeft: '8px',
                    color: '#324b54',
                    paddingBottom: '10px',
                    borderRadius: 0,
                    borderBottom: window.location.pathname === page.link ? '1px solid #324b54' : 'none',
                  }}
                >
                  {page.name}
                </Button>
              )
            })
          }
        </Box>
      </header>
    )
  }
  useEffect(() => {
    getAllDataFromPath('silos').then((res) => {
      if (res) {
        setSilos(res)
      }
    })
    getAllDataFromPath('categories').then((res) => {
      if (res) {
        setCategories(res)
      }
    })
  }, [])
  const calculateTotalItems = () => {
    if (miniCart) {
      const totalQuantity = miniCart.items.reduce((sum, item) => sum + (item.quantity || 1), 0)
      setCountCart(totalQuantity)
    }
  }
  useEffect(() => {
    calculateTotalItems()
  }, [miniCart])
  useEffect(() => {
    if (miniCart?.items?.length > 0) {
      LocalStorageHelper.set('cart', miniCart)
    }
  }, [miniCart])
  useEffect(() => {
    if (LocalStorageHelper.get('cart')) {
      setMinicart(LocalStorageHelper.get('cart'))
    }
  }, [])
  return (
    <Box sx={style.nav} className="navigation">
      <Box
        sx={{
          maxWidth: '1920px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: BreakpointsHelper.isMobile() ? '15px' : '50px',
        }}
      >
        <Box sx={style.menuRight}>
          <img src={logo} onClick={() => openLink('/home')} alt={'logo'} style={style.logo}/>
          {
            BreakpointsHelper.isMobile()
              ? (
                <div style={style.burger} onClick={() => setOpen(!open)}>
                  <MenuIcon style={style.menu}/>
                </div>
              )
              : silos?.length > 0 && generateMenu()
          }
          {
            open && (
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: '999',
                  background: 'white',
                  top: '80px',
                  left: '0',
                  height: 'calc(100vh - 90px)',
                  width: 'calc(100% - 40px)',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '20px'
                }}
              >
                {silos?.length > 0 && generateMenu()}
              </Box>
            )
          }
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ marginRight: '20px', top: '-3px', position: 'relative', cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => history.push('/login')}>
            <AccountCircleOutlined />
            <Typography sx={{ marginLeft: '10px' }}>
              {user?.name || ''}
            </Typography>
          </Box>
          {/*<SearchOutlined onClick={() => history.push('/serach')} sx={{ marginRight: '20px', top: '-3px', position: 'relative', cursor: 'pointer' }} />*/}
          {/*<FavoriteOutlined onClick={() => history.push('/profile?tab=wishlist')} sx={{ marginRight: '20px', top: '-3px', position: 'relative', cursor: 'pointer' }} />*/}
          <>
            <Badge
              badgeContent={countCart}
              color="primary"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <ShoppingCartOutlined onClick={() => setMinicartOpen(!minicartOpen)} sx={{ marginRight: '5px', top: '-3px', position: 'relative', cursor: 'pointer' }} />
            </Badge>
          </>
          {
            minicartOpen && (
              <Drawer
                anchor={'right'}
                open={minicartOpen}
                onClose={() => setMinicartOpen(false)}
              >
                <Minicart onClose={() => setMinicartOpen(false)} />
              </Drawer>
            )
          }
        </Box>
      </Box>
    </Box>
  )
}

export default NavigationPreview
