import SizeWindow from '../../components/atoms/SizeWindow/SizeWindow'
import Box from '@mui/material/Box'
import {useEffect, useState} from 'react'
import {getAllDataFromPath} from '../../firebase'
import Typography from '@mui/material/Typography'
import ArticlePreview from '../../components/molecules/ArticlePreview/ArticlePreview'
import {Grid} from '@mui/material'
import Seo from '../../components/molecules/Seo/Seo'
import PricesBox from '../../components/molecules/PricesBox/PricesBox'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'

const ShowCategoriesPage = () => {
  const [seoData, setSeoData] = useState({})
  const [nameSilos, setNameSilos] = useState(null)
  const [categoriesData, setCategoriesData] = useState(null)
  const [categoriesName, setCategoriesName] = useState(null)
  const [categoriesImage, setCategoriesImage] = useState(null)
  const [description, setDescription] = useState(null)
  const [count, setCount] = useState(0)
  const [data, setData] = useState([])
  const id = window.location.pathname.split('/')[2]
  const style = {
    banner: {
      height: '20px',
      padding: '100px',
      display: 'flex',
      alignItems: 'center',
      width: 'calc(100% - 200px)',
      justifyContent: 'center',
      background: `url(${categoriesImage})`,
      backgroundRepeat: 'none',
      backgroundSize: '100% 100%',
      borderRadius: '50px',
      backgroundPosition: 'center center',
      fitObject: 'cover',
      position: 'relative',
      marginBottom: '20px',
    },
    blackBg: {
      position: 'absolute',
      zIndex: 1,
      width: '100%',
      height: '100%',
      borderRadius: '50px',
      background: 'rgba(0,0,0,.3)'
    },
    texts: {
      position: 'absolute',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    title: {
      color: 'white',
      fontWeight: 900,
      fontSize: BreakpointsHelper.isMobile() ? '38px' : '65px',
      lineHeight: 1.1
    },
    desc: {
      marginTop: '20px',
      color: 'white',
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: 1.1
    }
  }
  useEffect(() => {
    if (id?.length > 0) {
      getAllDataFromPath('silos').then((res) => {
        if (res) {
          res?.forEach(el => {
            if (el?.id === id) {
              setNameSilos(el?.data?.name)
            }
          })
        }
      })
    }
  }, [id])
  useEffect(() => {
    getAllDataFromPath('categories').then((res) => {
      if (res) {
        res?.forEach(el => {
          if (el?.id === id) {
            const dataSeoTemp = {}
            dataSeoTemp['seo: category'] = el?.data['seo: category']
            dataSeoTemp['seo: description'] = el?.data['seo: description']
            dataSeoTemp['seo: title'] = el?.data['seo: title']
            dataSeoTemp['seo: fbPageId'] = el?.data['seo: fbPageId']
            dataSeoTemp['seo: keywords'] = el?.data['seo: keywords']
            dataSeoTemp['seo: ogDescription'] = el?.data['seo: ogDescription']
            dataSeoTemp['seo: ogImage'] = el?.data['seo: ogImage']
            dataSeoTemp['seo: ogTitle'] = el?.data['seo: ogTitle']
            dataSeoTemp['seo: page'] = el?.data['seo: page']
            dataSeoTemp['seo: prase'] = el?.data['seo: prase']
            dataSeoTemp['seo: twitterDescription'] = el?.data['seo: twitterDescription']
            dataSeoTemp['seo: twitterId'] = el?.data['seo: twitterId']
            dataSeoTemp['seo: twitterImage'] = el?.data['seo: twitterImage']
            dataSeoTemp['seo: twitterTitle'] = el?.data['seo: twitterTitle']
            setSeoData(dataSeoTemp)
            setNameSilos(null)
            setCategoriesData(el?.data)
            setCategoriesImage(el?.data?.image)
            setCategoriesName(el?.data?.name)
            setDescription(el?.data?.description)
          }
        })
      }
    })
  }, [nameSilos])
  useEffect(() => {
    if (categoriesName?.length > 0 || nameSilos?.length > 0) {
      getAllDataFromPath('articles').then((res) => {
        if (res) {
          let countArticles = 0
          const articles = []
          res?.forEach(el => {
            if (categoriesName) {
              if (el?.data?.category === id) {
                countArticles++
                articles.push(el)
              }
            } else {
              if (nameSilos) {
                if (el?.data?.silos === nameSilos) {
                  countArticles++
                  articles.push(el)
                }
              }
            }
          })
          setData(articles)
          setCount(countArticles)
        }
      })
    }
  }, [nameSilos, categoriesName])
  return (
    <>
      <Seo data={seoData}/>
        <SizeWindow justifyContent="flex-start">
          <Box sx={style.banner}>
            <Box sx={style.texts}>
              <Typography variant="h1" sx={style.title}>
                {categoriesName || nameSilos}
              </Typography>
              <Typography variant="h2" sx={style.desc}>
                Ilość artykułów: {count}
              </Typography>
            </Box>
            <Box sx={style.blackBg}/>
          </Box>
          <Grid container spacing={4}>
            <Grid item md={12} xs={12}>
              <Box className="editorText" dangerouslySetInnerHTML={{ __html: description }} />
            </Grid>
            {
              categoriesData?.priceName && categoriesData?.priceData && categoriesData?.price && (
                <Grid item md={12} xs={12}>
                  <PricesBox data={categoriesData}/>
                </Grid>
              )
            }
            {
              data?.length > 0 ? data?.map((el, index) => {
                const data = el?.data
                const id = el?.id
                return (
                  <Grid item md={3} xs={12} key={index}>
                    <ArticlePreview
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        cursor: 'pointer',
                        marginBottom: '20px'
                      }}
                      noAuthor
                      title={data?.title}
                      descriptionSmall={data?.description}
                      image={data?.image[0]}
                      id={id}
                    />
                  </Grid>
                )
              }) : (
                <Box sx={{
                  marginTop: '100px',
                  marginLeft: '30px',
                  textAlign: 'center',
                  width: '100%',
                }}
                >
                  <Typography
                    sx={{
                      fontSize: '26px',
                    }}
                  >
                    Nie ma dostępnych artykułów w tej kategorii
                  </Typography>
                </Box>
              )
            }
          </Grid>
        </SizeWindow>
    </>
  )
}

export default ShowCategoriesPage
