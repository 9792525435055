import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {useHistory} from 'react-router'
import useStore from '../../../store'
import {useEffect, useState} from 'react'
import {CloseOutlined} from '@mui/icons-material'
import SmallProduct from '../../molecules/SmallProduct/SmallProduct'

const Minicart = ({ onClose }) => {
  const history = useHistory()
  const minicart = useStore((state) => state.miniCart)
  const clearCart = useStore((state) => state.clearCart)
  const [price, setPrice] = useState(0)
  const handleClearCart = () => {
    clearCart()
    onClose && onClose()
    history.push('/shop?clearCart=true')
  }
  const getAllPrices = () => {
    let tempPrice = 0
    minicart?.items?.forEach((item) => {
      tempPrice += Number(item.regularPrice)
    })
    setPrice(tempPrice)
  }
  const handleCart = () => {
    history.push('/cart')
    onClose && onClose()
  }
  const style = {
    root: {
      display: 'flex',
      width: '300px',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      flexDirection: 'column',
      padding: '50px',
      height: '100%',
    },
    title: {
      marginBottom: '20px',
    },
    list: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flex: 1,
    },
    product: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      border: '1px solid #f3f3f3',
      padding: '10px',
      width: 'calc(100% - 20px)',
    },
    imageProduct: {
      maxWidth: '100%',
      width: 'auto',
      height: '80px',
      marginRight: '20px',
    }
  }
  useEffect(() => {
    getAllPrices()
  }, [])
  return (
    <Box sx={style.root}>
      <Typography variant="h5" sx={style.title}>Lista produktów</Typography>
      <Box sx={style.list}>
        {
          minicart?.items?.length > 0 ? (
            <SmallProduct minicart={minicart} />
          ) : (
            <Box>
              <Typography>Brak produktów w koszyku</Typography>
            </Box>
          )
        }
      </Box>
      <Box sx={{ width: '100%' }}>
        {
          minicart?.items?.length > 0 && (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography>Podsumowanie:</Typography>
                <Typography>{(Number(price) * 1.23).toFixed(2)} zl brutto</Typography>
              </Box>
              <Typography sx={{ marginBottom: '20px', textAlign: 'right', marginTop: '10px' }}>{Number(price).toFixed(2)} zl netto</Typography>
              <Button fullWidth variant="contained" sx={{ height: '40px', marginBottom: '10px' }} onClick={() => handleCart()}>Przejdź do koszyka</Button>
              <Button fullWidth variant="outlined" sx={{ height: '40px' }} onClick={() => handleClearCart()}>Wyczyść koszyk</Button>
            </>
          )
        }
      </Box>
      <Box sx={{ position: 'absolute', top: '30px', right: '30px', cursor: 'pointer' }} onClick={() => onClose()}>
        <CloseOutlined />
      </Box>
    </Box>
  )
}

export default Minicart
