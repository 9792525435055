import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import './AdminPage.scss'
import Typography from '@mui/material/Typography'
import {useEffect, useState} from 'react'
import {Grid, useTheme} from '@mui/material'
import {useSnackbar} from 'notistack'
import Tabs from '../../components/molecules/Tabs/Tabs'
import {
  deleteCollectionById,
  getAllDataFromPath,
  getAllOrders,
  getAllUsers,
  updateOrCreateDocument
} from '../../firebase'
import BasicTable from '../../components/atoms/Table/Table'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import SeoHelper from '../../helpers/SeoHelper/SeoHelper'
import Seo from '../../components/molecules/Seo/Seo'
import Taxes from '../../components/organisms/Taxes/Taxes'
import Companies from '../../components/organisms/Companies/Companies'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import {useHistory} from 'react-router'

const AdminPage = (props) => {
  const theme = useTheme()
  const seo = theme.config.seo
  const history = useHistory()
  const {enqueueSnackbar} = useSnackbar()
  const [users, setUsers] = useState(null)
  const [orders, setOrders] = useState(null)
  const [dataTabs, setDataTabs] = useState([])
  const [tournaments, setTournaments] = useState(null)
  const [tags, setTags] = useState(null)
  const [categories, setCategories] = useState(null)
  const [articles, setArticles] = useState(null)
  const [galleries, setGalleries] = useState(null)
  const [silos, setSilos] = useState(null)
  const [silosItems, setSilosItems] = useState(null)
  const [silosItemsSmall, setSilosItemsSmall] = useState(null)
  const [checkSilos, setCheckSilos] = useState(null)
  const [checkCategories, setCheckCategories] = useState([])
  const [allArticlesItems, setAllArticlesItems] = useState([])
  const [articlesBasic, setArticlesBasic] = useState([])
  const notificationType = props?.location?.location?.search?.replace('?', '').split('=')[0]
  const [taxes] = useState([
    {
      pkd: '62.01.Z',
      name: 'Działalność związana z oprogramowaniem',
      services: 'Aplikacje webowe, Strona internetowa, Bazy danych, Konfiguracja serwerowa, Konfiguracja chmurowa, Konfiguracja domeny, SSL,  Konfiguracja email, Aplikacja mobilna, Statystyki strony internetowej, Statystyki reklam internetowych, Analiza danych reklamowych',
      pkwiu: [
        '62.01.11.0',
        '62.01.12.0',
        '62.01.29.0'
      ],
      tax: '12%'
    },
    {
      pkd: '18.12.Z',
      name: 'Pozostałe drukowanie',
      services: 'Materiały reklamowe drukowane',
      pkwiu: [
        '18.12.12.0',
        '18.12.13.0',
        '18.12.14.0',
        '18.12.15.0',
        '18.12.16.0',
        '18.12.19.0'
      ],
      tax: '8,50%'
    },
    {
      pkd: '18.13.Z',
      name: 'Działalność usługowa związana z przygotowywaniem do druku',
      services: 'Materiały reklamowe drukowane, Plakat, Baner, Ulotka, Katalog, Logo, Druk dla firm',
      pkwiu: [
        '18.13.10.0',
        '18.13.30.0'
      ],
      tax: '8,50%'
    },
    {
      pkd: '32.40.Z',
      name: 'Produkcja gier i zabawek',
      services: 'Wykonanie gry planszowej',
      pkwiu: [
        '32.40.32.0',
        '32.40.39.0',
        '32.40.41.0',
        '32.40.99.0'
      ],
      tax: '5,50%'
    },
    {
      pkd: '47.65.Z',
      name: 'Sprzedaż detaliczna gier i zabawek prowadzona w wyspecjalizowanych sklepach',
      services: 'Sprzedaż gier planszowych od wydawnictw',
      pkwiu: [],
      tax: '3%'
    },
    {
      pkd: '47.71.Z',
      name: 'Sprzedaż detaliczna gier i zabawek prowadzona w wyspecjalizowanych sklepach',
      services: 'Sprzedaż koszulek planszówek',
      pkwiu: [],
      tax: '3%'
    },
    {
      pkd: '47.91.Z',
      name: 'Sprzedaż detaliczna prowadzona przez domy sprzedaży wysyłkowej lub Internet',
      services: 'Sprzedaż gier planszowych',
      pkwiu: [],
      tax: '3%'
    },
    {
      pkd: '58.21.Z',
      name: 'Działalność wydawnicza w zakresie gier komputerowych',
      services: 'Wydanie gry komputerowej, Wydanie gry sieciowej',
      pkwiu: [
        '58.21.10.0',
        '58.21.20.0 ',
        '58.21.30.0',
        '58.21.40.0'
      ],
      tax: '12%'
    },
    {
      pkd: '58.29.Z',
      name: 'Działalność wydawnicza w zakresie pozostałego oprogramowania',
      services: 'Wydanie oprogramowania z abonamentem',
      pkwiu: [
        '58.29.11.0',
        '58.29.12.0',
        '58.29.13.0',
        '58.29.14.0',
        '58.29.21.0',
        '58.29.29.0',
        '58.29.40.0'
      ],
      tax: '12%'
    },
    {
      pkd: '59.12.Z',
      name: 'Działalność postprodukcyjna związana z filmami, nagraniami wideo i programami telewizyjnymi',
      services: 'Projekty wideo, Animacje komputerowe',
      pkwiu: [
        '59.12.11.0 ',
        '59.12.12.0',
        '59.12.13.0',
        '59.12.14.0',
        '59.12.15.0',
        '59.12.16.0',
        '59.12.17.0',
        '59.12.19.0 '
      ],
      tax: '8,50%'
    },
    {
      pkd: '62.02.Z',
      name: 'Działalność związana z doradztwem w zakresie informatyki',
      services: 'Spotkania z doradztwem w zakresie informatyki, Analiza i doradztwo w zakresie informatyki',
      pkwiu: [
        '62.02.10.0',
        '62.02.20.0',
        '62.02.30.0'
      ],
      tax: '12%'
    },
    {
      pkd: '62.03.Z',
      name: 'Działalność związana z zarządzaniem urządzeniami informatycznymi',
      services: 'Naprawianie komputerów u klienta',
      pkwiu: [
        '62.03.11.0 ',
        '62.03.12.0'
      ],
      tax: '12%'
    },
    {
      pkd: '62.09.Z',
      name: 'Pozostała działalność usługowa w zakresie technologii informatycznych i komputerowych',
      services: 'Usługi odzyskiwania danych u klienta',
      pkwiu: [
        '62.09.10.0',
        '62.09.20.0'
      ],
      tax: '12%'
    },
    {
      pkd: '63.11.Z',
      name: 'Przetwarzanie danych; zarządzanie stronami internetowymi (hosting) i podobna działalność',
      services: 'Administracja stroną internetową, Administracja aplikacją webową, Udostępnianie własnych serwerów, Miejsce na reklamę w internecie',
      pkwiu: [
        '63.11.11.0',
        '63.11.12.0',
        '63.11.13.0',
        '63.11.19.0',
        '63.11.20.0'
      ],
      tax: '8,50%'
    },
    {
      pkd: '63.12.Z',
      name: 'Działalność portali internetowych',
      services: 'Opłata za wrzucanie informacji na temat firmy na moich stronach (opłata abonamentowa)',
      pkwiu: [
        '63.12.20.0'
      ],
      tax: '15%'
    },
    {
      pkd: '63.91.Z',
      name: 'Działalność agencji informacyjnych',
      services: 'Listy adresowe w excel, Listy danych o potencjalnych klientach',
      pkwiu: [
        '63.99.10.0',
        '63.99.20.0'
      ],
      tax: '15%'
    },
    {
      pkd: '71.12.Z',
      name: 'Działalność w zakresie inżynierii i związane z nią doradztwo techniczne',
      services: 'Analiza i doradztwo uszkodzonego oprogramowania',
      pkwiu: [
        '71.12.11.0 '
      ],
      tax: '14%'
    },
    {
      pkd: '73.11.Z',
      name: 'Działalność agencji reklamowych',
      services: 'Kampanie reklamowe, Reklama w social mediach, Grafiki na materiały reklamowe',
      pkwiu: [],
      tax: '15%'
    },
    {
      pkd: '74.10.Z',
      name: 'Działalność w zakresie specjalistycznego projektowania',
      services: 'Projekt wystroju wnętrz i dekoracji, SEO, Email marketing',
      pkwiu: [
        '74.10.11.0 ',
        '74.10.19.0',
        '74.10.20.0'
      ],
      tax: '14%'
    },
    {
      pkd: '82.30.Z',
      name: 'Działalność związana z organizacją targów, wystaw i kongresów',
      services: '',
      pkwiu: [
        '82.30.11.0',
        '82.30.12.0'
      ],
      tax: '15%'
    },
    {
      pkd: '85.59.B',
      name: 'Pozostałe pozaszkolne formy edukacji, gdzie indziej niesklasyfikowane',
      services: 'Prowadzenie szkoleń z programowania lub AI, Kursy komputerowe, Usługi w zakresie doskonalenia zawodowego nauczycieli, Korepetycje udzielane w domu, Pozaszkolna forma edukakcji',
      pkwiu: [
        '85.59.12.0 ',
        '85.59.13.1',
        '85.59.13.2',
        '85.59.14.0',
        '85.59.19.0'
      ],
      tax: '8,50%'
    },
    {
      pkd: '46.18.Z',
      name: 'Usługi pośrednictwa w sprzedaży hurtowej pozostałych określonych towarów',
      services: 'Usługi pośrednictwa w sprzedaży hurtowej gier i zabawek',
      pkwiu: [
        '46.18.12.0'
      ],
      tax: '15%'
    },
    {
      pkd: '46.19.Z',
      name: 'Działalność agentów zajmujących się sprzedażą towarów różnego rodzaju (w tym pośrednictwo)',
      services: 'Usługi pośrednictwa w sprzedaży detalicznej gier i zabawek',
      pkwiu: [],
      tax: '15%'
    }
  ])
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    },
    arrayNotExist: {
      fontSize: '16px',
      marginTop: '20px',
      color: '#324b54',
      background: '#ededed',
      padding: '20px',
      width: 'auto',
      borderRadius: '50px',
    }
  }
  const RenderElementToShow = ({ children, method, possibleToDelete = true, id, edit}) => {
    return (
      <Box
        sx={{
          display: 'flex',
          width: 'calc(100% - 40px)',
          padding: '20px',
          boxShadow: '2px 2px 5px 2px rgba(0,0,0,.1)',
          marginTop: '20px'
        }}
      >
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {children}
              <Box sx={{ display: 'flex' }}>
                {
                  edit && (
                    <Button sx={{ height: '40px', fontSize: '14px', marginLeft: '20px', width: '200px' }} variant="contained" onClick={() => edit?.method()}>{edit?.name}</Button>
                  )
                }
                {
                  possibleToDelete && (
                    <Button
                      sx={{ background: 'red', marginLeft: '20px', minWidth: '80px' }}
                      variant="contained"
                      onClick={() => method(id)}
                    >
                      Usuń
                    </Button>
                  )
                }
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }
  const updateUsers = () => {
    getAllUsers().then((res) => {
      if (res) {
        const tempData = []
        res?.forEach(el => {
          console.log(el)
          const element = el?.data
          tempData.push({
            uid: element?.uid,
            email: element?.email,
            name: element?.name,
            phone: element?.phone,
            role: element?.role
          })
        })
        setUsers(tempData)
      }
    })
  }
  const updateOrders = () => {
    getAllOrders().then((res) => {
      if (res) {
        const tempData = []
        res?.forEach(el => {
          console.log(el)
          const element = el?.data
          tempData.push({
            orderId: el?.id,
            // product: element?.cart?.items,
            status: 'Pending',
            totalAmount: `${(Number(element?.data?.totalAmount) / 100).toFixed(2)} zł`,
            method: element?.cartInfo?.method,
            name: element?.cartInfo?.name,
            email: element?.data?.buyer?.email,
            phone: element?.cartInfo?.name,
            address: element?.cartInfo?.address,
            postalCode: element?.cartInfo?.postalCode,
            city: element?.cartInfo?.city,
            region: element?.cartInfo?.region,
            country: 'Polska',
          })
        })
        setOrders(tempData)
      }
    })
  }
  const updateTournaments = () => {
    getAllDataFromPath('tournaments').then((res) => {
      if (res) {
        setTournaments(res)
      }
    })
  }
  const updateTags = () => {
    getAllDataFromPath('tags').then((res) => {
      if (res) {
        res.sort((a, b) => {
          const fa = a?.data?.silos?.toLowerCase()
          const fb = b?.data?.silos?.toLowerCase()
          if (fa < fb) return -1
          if (fa > fb) return 1
          return 0
        })
        const tempItems = []
        res?.forEach(el => {
          tempItems.push({ name: el?.data?.name, value: el?.data?.name })
        })
        setTags(res)
      }
    })
  }
  const updateCategories = () => {
    getAllDataFromPath('categories').then((res) => {
      if (res) {
        res.sort((a, b) => {
          const fa = a?.data?.silos?.toLowerCase()
          const fb = b?.data?.silos?.toLowerCase()
          if (fa < fb) return -1
          if (fa > fb) return 1
          return 0
        })
        setCategories(res)
      }
    })
  }
  const updateSilos = () => {
    getAllDataFromPath('silos').then((res) => {
      if (res) {
        setSilos(res)
      }
    })
  }
  const updateArticles = () => {
    getAllDataFromPath('articles').then((res) => {
      if (res) {
        setArticles(res)
      }
    })
  }
  const updateGaleries = () => {
    getAllDataFromPath('galleries').then((res) => {
      if (res) {
        setGalleries(res)
      }
    })
  }
  const handleArticles = (e) => {
    updateOrCreateDocument('articles', null, e).then((res) => {
      if (res) {
        enqueueSnackbar('Poprawnie dodano artykuł.', {variant: 'success'})
        updateArticles()
      }
    })
  }
  const handleArticlesBasic = (e) => {
    updateOrCreateDocument('articlesBasic', 'config', e).then((res) => {
      if (res) {
        enqueueSnackbar('Poprawnie dodano artykuł.', {variant: 'success'})
        updateArticles()
      }
    })
  }
  const handleTags = (e) => {
    updateOrCreateDocument('tags', null, e).then((res) => {
      if (res) {
        enqueueSnackbar('Poprawnie dodano tag, jest już dostępny przy budowie artykułów.', {variant: 'success'})
      }
    })
  }
  const handleTournament = (e) => {
    updateOrCreateDocument('tournaments', null, e).then((res) => {
      if (res) {
        enqueueSnackbar('Poprawnie dodano zawody.', {variant: 'success'})
        updateTournaments()
      }
    })
  }
  const handleCategories = (e) => {
    updateOrCreateDocument('categories', null, e).then((res) => {
      if (res) {
        enqueueSnackbar('Poprawnie dodano kategorię.', {variant: 'success'})
        updateCategories()
      }
    })
  }
  const handleSilos = (e) => {
    updateOrCreateDocument('silos', null, e).then((res) => {
      if (res) {
        enqueueSnackbar('Poprawnie dodano silos.', {variant: 'success'})
        updateSilos()
      }
    })
  }
  const deleteTag = (id) => {
    deleteCollectionById('tags', id).then(() => {
      setTags(null)
      enqueueSnackbar('Poprawnie usunięto tag.', {variant: 'success'})
      updateTags()
    })
  }
  const deleteCategory = (id) => {
    deleteCollectionById('categories', id).then(() => {
      setCategories(null)
      enqueueSnackbar('Poprawnie usunięto kategorie.', {variant: 'success'})
      updateCategories()
    })
  }
  const deleteSilos = (id) => {
    deleteCollectionById('silos', id).then(() => {
      setSilos(null)
      enqueueSnackbar('Poprawnie usunięto silos.', {variant: 'success'})
      updateSilos()
    })
  }
  const deleteArticle = (id) => {
    deleteCollectionById('articles', id).then(() => {
      setSilos(null)
      enqueueSnackbar('Poprawnie usunięto artykuł.', {variant: 'success'})
      updateArticles()
    })
  }
  const deleteGallery = (id) => {
    deleteCollectionById('galleries', id).then(() => {
      setSilos(null)
      enqueueSnackbar('Poprawnie usunięto kategorię galerii.', {variant: 'success'})
      updateGaleries()
    })
  }
  const deleteTournament = (id) => {
    deleteCollectionById('tournaments', id).then(() => {
      setTournaments(null)
      enqueueSnackbar('Poprawnie usunięto zawody.', {variant: 'success'})
      updateTournaments()
    })
  }
  useEffect(() => {
    if (notificationType) {
      switch (notificationType) {
        case 'deleteImageFromGallery':
          enqueueSnackbar('Poprawnie usunięto zdjęcie z galerii.', {variant: 'success'})
          break
      }
    }
  }, [notificationType])
  useEffect(() => {
    updateUsers()
    updateOrders()
    updateTournaments()
    updateTags()
    updateCategories()
    updateArticles()
    updateSilos()
    updateGaleries()
  }, [])
  useEffect(() => {
    if (users && checkCategories) {
      const formData = {
        elements: [
          {
            name: 'name',
            label: 'Nazwa produktu',
          },
          {
            name: 'description',
            type: 'editor',
            label: 'Opis',
          },
          {
            name: 'image',
            type: 'files',
            pathname: 'files',
            label: 'Zdjęcie produktu',
          },
          {
            name: 'regularPrice',
            label: 'Cena regularna netto',
          },
          {
            type: 'button',
            value: 'Dodaj dane'
          }
        ]
      }
      const formDataTags = {
        elements: [
          {
            name: 'silos',
            type: 'select',
            label: 'Wybierz silos, z którego tag pochodzi',
            items: silosItems,
          },
          {
            name: 'name',
            label: 'Nazwa tagu',
            type: 'text',
          },
          {
            type: 'button',
            value: 'Dodaj tag',
          }
        ],
      }
      const checkTags = []
      if (tags && checkSilos) {
        tags?.forEach((el) => {
          if (el.data.silos === checkSilos) {
            checkTags.push({ value: el.id, name: el.data.name })
          }
        })
      }
      const formDataArticles = {
        elements: [
          {
            name: 'silos',
            type: 'select',
            label: 'Wybierz silos, z którego artykuł pochodzi',
            items: silosItemsSmall,
            onChange: (e) => setCheckSilos(e.target.value),
          },
          {
            name: 'category',
            label: 'Wybierz kategorie artykułu',
            type: 'select',
            items: checkCategories,
          },
        ],
      }
      const formDataArticlesBasic = {
        elements: [
          {
            name: 'category1',
            label: 'Wybierz artykuł 1',
            type: 'select',
            value: articlesBasic[0]?.data?.category1,
            items: allArticlesItems,
          },
          {
            name: 'category2',
            label: 'Wybierz artykuł 2',
            type: 'select',
            value: articlesBasic[0]?.data?.category2,
            items: allArticlesItems,
          },
          {
            name: 'category3',
            label: 'Wybierz artykuł 3',
            type: 'select',
            value: articlesBasic[0]?.data?.category3,
            items: allArticlesItems,
          },
          {
            name: 'category4',
            label: 'Wybierz artykuł 4',
            type: 'select',
            value: articlesBasic[0]?.data?.category4,
            items: allArticlesItems,
          },
          {
            type: 'button',
            value: 'Zapisz główne artykuły',
          }
        ],
      }
      if (checkTags?.length > 0) {
        formDataArticles.elements.push({
          name: 'tags',
          label: 'Wybierz tagi artykułu',
          type: 'multiSelect',
          items: checkTags,
        })
      }
      formDataArticles.elements.push({
          name: 'title',
          label: 'Tytuł artykułu',
          type: 'text',
        },
        {
          name: 'description',
          label: 'Opis artykułu',
          type: 'editor',
        })
      formDataArticles.elements.push({
        name: 'image',
        label: 'Zdjęcie główne',
        type: 'files',
        pathname: 'files',
      })
      SeoHelper.addFieldSeo(formDataArticles)
      formDataArticles.elements.push({
        type: 'button',
        value: 'Dodaj artykuł',
      })
      const formDataCategories = {
        elements: [
          {
            name: 'silos',
            type: 'select',
            label: 'Wybierz silos, z którego tag pochodzi',
            items: silosItems,
          },
          {
            name: 'name',
            label: 'Nazwa kategorii',
            type: 'text',
          },
          {
            name: 'description',
            label: 'Opis kategorii',
            type: 'editor',
          },
          {
            name: 'image',
            label: 'Zdjęcie główne',
            type: 'files',
            pathname: 'files',
          },
          {
            name: 'priceName',
            label: 'Nazwa usługi',
            type: 'text',
          },
          {
            name: 'priceData',
            label: 'Co jest w pakiecie',
            type: 'editor',
          },
          {
            name: 'price',
            label: 'Cena usługi',
            helperText: 'Podane w groszach',
            type: 'number',
          }
        ],
      }
      SeoHelper.addFieldSeo(formDataCategories)
      formDataCategories.elements.push({ type: 'button', value: 'Dodaj kategorię' })
      const formDataSilos = {
        elements: [
          {
            name: 'silos',
            type: 'select',
            label: 'Wybierz typ silosu',
            items: [{ name: 'menu', value: 'menu'}, { name: 'oferta', value: 'oferta'}, { name: 'blog', value: 'blog'}],
          },
          {
            name: 'name',
            label: 'Nazwa silosu',
            type: 'text',
          },
          {
            type: 'button',
            value: 'Dodaj silos',
          }
        ],
      }
      setDataTabs([
        {
          name: 'Użytkownicy',
          value: () => (
            <>
              {users && <BasicTable data={users} collection={'users'} callback={() => updateUsers()}/>}
            </>
          )
        },
        {
          name: 'Zamówienia',
          value: () => (
            <>
              {orders && <BasicTable data={orders} disabledEdit />}
            </>
          )
        },
        {
          name: 'Produkty',
          value: () => (
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <Typography>Lista utworzonych produktów</Typography>
                {
                  tournaments?.length > 0 ? tournaments?.map((el, index) => {
                    const data = el?.data
                    const id = el?.id
                    const children = (
                      <>
                        <Typography
                          sx={{
                            fontSize: '20px',
                            textTransform: 'uppercase',
                            fontWeight: '600'
                          }}
                        >
                          {data?.name}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                          {
                            data?.person?.map((el, index) => {
                              return (
                                <Typography key={index}>
                                  {`${el?.company ? el?.company + ', ' : ''} ${el?.name || ''}`}
                                </Typography>
                              )
                            })
                          }
                        </Box>
                      </>
                    )
                    return <RenderElementToShow key={index} method={deleteTournament} id={id}>{children}</RenderElementToShow>
                  }) : (
                    <Typography sx={style.arrayNotExist}>Nie ma dostępnych produktów.</Typography>
                  )
                }
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>Dodaj nowy produkt</Typography>
                <FormGenerator data={formData} submit={(e) => handleTournament(e)}/>
              </Grid>
            </Grid>
          )
        },
        {
          name: 'Silosy',
          value: () => (
            <Grid container spacing={4}>
              <Grid item md={7} xs={12}>
                <Typography>List dostępnych silosów</Typography>
                {
                  silos?.length ? silos?.map((el, index) => {
                    const data = el?.data
                    const id = el?.id
                    const children = (
                      <>
                        <Box>
                          <Typography sx={{ fontSize: '14px' }}>{data?.silos}</Typography>
                          <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>{data?.name}</Typography>
                        </Box>
                      </>
                    )
                    let checkIsPossible = true
                    categories?.length && categories?.forEach(el => {
                      const dataEl = el?.data
                      if (dataEl?.silos === data?.name) {
                        checkIsPossible = false
                      }
                    })
                    return <RenderElementToShow key={index} method={deleteSilos} edit={{ name: 'Edycja silosu', method: () => window.open(`/editSilos/${id}`, '_self')}} possibleToDelete={checkIsPossible} id={id}>{children}</RenderElementToShow>
                  }) : (
                    <Typography sx={{
                      fontSize: '16px',
                      marginTop: '20px',
                      color: '#324b54',
                      background: '#ededed',
                      padding: '20px',
                      width: 'auto',
                      borderRadius: '50px'
                    }}>Nie ma dostępnych silosów.</Typography>
                  )
                }
              </Grid>
              <Grid item md={5} xs={12}>
                <Typography>Tworzenie nowego silosu</Typography>
                <FormGenerator data={formDataSilos} submit={(e) => handleSilos(e)}/>
              </Grid>
            </Grid>
          )
        },
        {
          name: 'Kategorie',
          value: () => (
            <Grid container spacing={4}>
              <Grid item md={7} xs={12}>
                <Typography>List dostępnych kategorii</Typography>
                {
                  categories?.length ? categories?.map((el, index) => {
                    const data = el?.data
                    const id = el?.id
                    const children = (
                      <>
                        <Box>
                          <Typography sx={{ fontSize: '14px' }}>{data?.silos}</Typography>
                          <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>{data?.name}</Typography>
                        </Box>
                      </>
                    )
                    return <RenderElementToShow key={index} method={deleteCategory} edit={{ name: 'Edycja kategorii', method: () => window.open(`/editCategory/${id}`, '_self')}} id={id}>{children}</RenderElementToShow>
                  }) : <Typography sx={style.arrayNotExist}>Nie ma dostępnych kategorii.</Typography>
                }
              </Grid>
              <Grid item md={5} xs={12}>
                <Typography>Tworzenie nowej kategorii</Typography>
                {
                  silos?.length > 0 && (
                    <FormGenerator data={formDataCategories} submit={(e) => handleCategories(e)}/>
                  )
                }
              </Grid>
            </Grid>
          )
        },
        {
          name: 'Tagi',
          value: () => {
            return (
              <Grid container spacing={4}>
                <Grid item md={7} xs={12}>
                  <Typography>List dostępnych tagów</Typography>
                  {
                    tags?.length ? tags?.map((el, index) => {
                      const data = el?.data
                      const id = el?.id
                      const children = (
                        <>
                          <Box>
                            <Typography sx={{ fontSize: '14px' }}>{data?.silos}</Typography>
                            <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>{data?.name}</Typography>
                          </Box>
                        </>
                      )
                      return <RenderElementToShow key={index} method={deleteTag} edit={{ name: 'Edycja tagu', method: () => window.open(`/editTag/${id}`, '_self')}} id={id}>{children}</RenderElementToShow>
                    }) : (
                      <Typography sx={style.arrayNotExist}>Nie ma dostępnych tagów.</Typography>
                    )
                  }
                </Grid>
                <Grid item md={5} xs={12}>
                  <Typography>Tworzenie nowego tagu</Typography>
                  <FormGenerator data={formDataTags} submit={(e) => handleTags(e)}/>
                </Grid>
              </Grid>
            )
          }
        },
        {
          name: 'Artykuły',
          value: () => (
            <Grid container spacing={4}>
              <Grid item md={7} xs={12}>
                <Typography>List dostępnych artykułów</Typography>
                {
                  articles?.length ? articles?.map((el, index) => {
                    const data = el?.data
                    const id = el?.id
                    const children = (
                      <>
                        <Box>
                          <Typography sx={{ fontSize: '14px' }}>{data?.silos}</Typography>
                          <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>{data?.title}</Typography>
                        </Box>
                      </>
                    )
                    return <RenderElementToShow key={index} method={deleteArticle} edit={{ name: 'Edycja artykułu', method: () => window.open(`/editArticle/${id}`, '_self')}} id={id}>{children}</RenderElementToShow>
                  }) : (
                    <Typography sx={style.arrayNotExist}>Nie ma dostępnych artykułów.</Typography>
                  )
                }
              </Grid>
              <Grid item md={5} xs={12}>
                <Typography>Tworzenie nowego artykułu</Typography>
                {
                  checkCategories && <FormGenerator data={formDataArticles} submit={(e) => handleArticles(e)}/>
                }
              </Grid>
            </Grid>
          )
        },
        {
          name: 'Wybierz artykuły główne',
          value: () => (
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <Typography>Ustawianie artykułów głównych</Typography>
                {
                  checkCategories && <FormGenerator data={formDataArticlesBasic} submit={(e) => handleArticlesBasic(e)}/>
                }
              </Grid>
            </Grid>
          )
        },
        {
          name: 'Progi podatkowe',
          value: () => (
            <Taxes />
          )
        },
        {
          name: 'Dane kontrahentów',
          value: () => (
            <Companies />
          )
        },
      ])
    }
  }, [users, tournaments, tags, categories, silos, silosItems, articles, checkCategories, galleries, silosItemsSmall])
  useEffect(() => {
    if (silos?.length > 0) {
      const temp = []
      const tempSmall = []
      silos?.forEach(el => {
        const data = el?.data
        let isAdd = false
        categories.forEach((element) => {
          if (element.data.silos === el.data.name) {
            isAdd = true
          }
        })
        if (isAdd) {
          tempSmall.push({ value: data?.name, name: data?.name })
        }
        temp.push({ value: data?.name, name: data?.name })
      })
      setSilosItems(temp)
      setSilosItemsSmall(tempSmall)
    }
  }, [silos])
  useEffect(() => {
    if (checkSilos && categories) {
      const tempCat = []
      categories?.forEach((el) => {
        if (el.data.silos === checkSilos) {
          tempCat.push({ value: el.id, name: el.data.name })
        }
      })
      setCheckCategories(tempCat)
    }
  }, [checkSilos, categories])
  useEffect(() => {
    if (articlesBasic) {
      getAllDataFromPath('articles').then((res) => {
        if (res) {
          const tempCatBasic = []
          res?.forEach(el => {
            tempCatBasic.push({ value: el.id, name: el.data.title })
          })
          setAllArticlesItems(tempCatBasic)
        }
      })
    }
  }, [checkSilos, categories, articlesBasic])
  useEffect(() => {
    getAllDataFromPath('articlesBasic').then((res) => {
      if (res) {
        setArticlesBasic(res)
      }
    })
    if (LocalStorageHelper.get('user').role === 'user') {
      history.push('/home')
    }
  }, [])
  return (
    <>
      <Seo data={{ 'seo: title': `${seo.company} - Panel administracyjny` }}/>
      <div style={style.root}>
        <Grid container spacing={8}>
          <Grid item md={12} xs={12}>
            {
              dataTabs && (
                <Tabs data={dataTabs}/>
              )
            }
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default AdminPage
