import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import {Button, Grid, useTheme} from '@mui/material'
import Box from '@mui/material/Box'
import SizeWindow from '../../atoms/SizeWindow/SizeWindow'
import Dot from '../../atoms/Dot/Dot'
import Typography from '@mui/material/Typography'

const ContactBanner = () => {
  const theme = useTheme()
  const seo = theme.config.seo
  const style = {
    root: {
      background: theme.palette.secondary.second,
      padding: BreakpointsHelper.isMobile() ? '30px' : '100px 100px 100px 100px',
      display: 'flex',
      minHeight: '500px',
      borderRadius: '3px',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: '50px',
    },
    title: {
      color: 'white',
      fontSize: BreakpointsHelper.isMobile() ? '38px' : '72px',
      lineHeight: '1.1',
      marginBottom: '20px',
      fontWeight: '600',
    },
    small: {
      color: 'white',
      fontSize: BreakpointsHelper.isMobile() ? '22px' : '32px',
      lineHeight: '1.1',
      marginBottom: '20px',
      fontWeight: '400',
    }
  }
  return (
    <SizeWindow>
      <Box sx={style.root}>
        <Grid container spacing={4}>
          <Grid item lg={9} md={12} sm={12} xs={12}>
              <Typography sx={style.title}>
                Skorzystaj z osobistej<br/> konsultacji<Dot/>
              </Typography>
            <Typography sx={style.small}>
              Potrzebujesz pomocy, napisz lub zadzwoń.
            </Typography>
          </Grid>
          <Grid item lg={3} md={12} sm={12} xs={12}>
           <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100%' }}>
             <Button
               fullWidth
               sx={{ padding: '20px 40px', color: 'white', borderRadius: '3px', height: 'auto', fontWeight: '600' }}
               onClick={() => window.open(`tel:${seo.phone.replace(' ', '').replace(' ', '').replace(' ', '')}`, '_blank')}
               variant="contained"
             >
               {seo.phone}
             </Button>
             <Button
               fullWidth
               sx={{ padding: '20px 40px', color: '#2c296a', marginTop: '20px', background: 'white', borderRadius: '3px', fontWeight: '600', height: 'auto' }}
               onClick={() => window.open(`mailto:${seo.email}`, '_blank')}
               variant="outlined"
             >
               {seo.email}
             </Button>
           </Box>
          </Grid>
        </Grid>
      </Box>
    </SizeWindow>
  )
}

export default ContactBanner
