import {useEffect, useState} from 'react'
import {getAllDataFromPath} from '../../firebase'
import Product from '../../components/molecules/Product/Product'
import {Grid, useTheme} from '@mui/material'
import SizeWindow from '../../components/atoms/SizeWindow/SizeWindow'
import Typography from '@mui/material/Typography'
import Dot from '../../components/atoms/Dot/Dot'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import Seo from '../../components/molecules/Seo/Seo'
import {useSnackbar} from 'notistack'

const ShopPage = () => {
  const theme = useTheme()
  const {enqueueSnackbar} = useSnackbar()
  const seo = theme.config.seo
  const currentUrl = window.location.href
  const url = new URL(currentUrl)
  const params = new URLSearchParams(url.search)
  const clearCart = params.get('clearCart')
  const [products, setProducts] = useState([])
  const title = 'Najlepsze produkty, niskie ceny, szybka dostawa'
  const small = 'Twój Ulubiony Sklep Online'
  const style = {
    title: {
      fontSize: '30px',
      fontWeight: '600',
      color: '#2c296a',
      textAlign: 'left',
    },
    small: {
      fontSize: BreakpointsHelper.isMobile() ? '31px' : '52px',
      fontWeight: '600',
      marginBottom: '30px',
      maxWidth: '720px',
      textAlign: 'left',
    },
  }
  const fetchProducts = async () => {
    const allProducts = await getAllDataFromPath('tournaments')
    setProducts(allProducts)
  }
  useEffect(() => {
    fetchProducts()
  }, [])
  useEffect(() => {
    if (clearCart) {
      enqueueSnackbar('Poprawnie wyczyszczono produkty w koszyku.', {variant: 'success'})
    }
  }, [clearCart])
  return (
    <SizeWindow>
      <Seo data={{'seo: title': `${seo.company} - Sklep`}}/>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          {
            title && (
              <Typography variant="h1" sx={style.title}>{title}</Typography>
            )
          }
          {
            small && (
              <Typography variant="h2" sx={style.small}>{small}<Dot /></Typography>
            )
          }
        </Grid>
        <Grid item md={12} xs={12}>
          <Grid container spacing={4}>
            {
              products?.map((product, index) => (
                <Grid key={index} item md={3} xs={12}>
                  <Product product={product} />
                </Grid>
              ))
            }
          </Grid>
        </Grid>
      </Grid>
    </SizeWindow>
  )
}

export default ShopPage
