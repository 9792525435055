export default {
  getPostalCode: (adres) => {
    const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(adres)}&format=json&addressdetails=1`
    return fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0 && data[0].address) {
          return data?.[0]?.address?.postcode
        } else {
          return null
        }
      })
      .catch(error => {
        console.error('Wystąpił błąd podczas pobierania kodu pocztowego:', error)
      })
  }
}
