import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import Box from '@mui/material/Box'
import {Grid, Link, useTheme} from '@mui/material'
import logo from '../../../assets/images/logo-small.png'
import logoWebP from '../../../assets/images/logo-small.webp'
import Typography from '@mui/material/Typography'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import Image from '../../../components/atoms/Image/Image'
import policy from '../../../assets/files/policy.pdf'
import regulation from '../../../assets/files/regulation.pdf'
import methods from '../../../assets/files/methods.pdf'
import time from '../../../assets/files/time.pdf'
import reclamation from '../../../assets/files/reclamation.pdf'
import orders from '../../../assets/files/orders.pdf'
import SizeWindow from '../../atoms/SizeWindow/SizeWindow'

const Footer = () => {
  const t = LocalStorageHelper.get('translation')
  const theme = useTheme()
  const { company, phone, email, address, facebook, youtube, instagram, hours, smallDescription } = theme.config.seo
  const style = {
    h2: {
      fontSize: '38px',
      fontWeight: '800',
      textAlign: 'left',
      marginTop: '10px',
      width: '100%'
    },
    h3: {
      fontSize: '22px',
      fontWeight: '900',
      marginTop: '10px',
      textAlign: 'left',
      width: '100%',
      marginBottom: '20px',
    },
    image: {
      width: '100%',
      display: 'flex',
      maxWidth: '120px',
      marginBottom: '30px'
    }
  }
  return (
    <SizeWindow>
      <Box
        sx={{
          display: 'flex',
          alignItems: BreakpointsHelper.isMobile() ? 'flex-start' : 'center',
          flexDirection: BreakpointsHelper.isMobile() && 'column',
          width: '100%',
          borderTop: '1px solid rgb(229,231,235)',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-start', padding: '50px 0 0 0'}}>
          <Grid container spacing={BreakpointsHelper.isMobile() ? 0 : 8}>
            <Grid item md={3} xs={12}>
              <Image
                loading="lazy"
                src={logo}
                srcWebP={logoWebP}
                alt="logo"
                style={style.image}
              />
              {
                smallDescription && (
                  <Typography variant="p" sx={{width: '100%'}}>
                    {smallDescription}
                  </Typography>
                )
              }
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography className="bold" variant="h2" sx={style.h3}>
                Informacje podstawowe
              </Typography>
              <Typography sx={{ marginTop: '10px' }}><b>Telefon: </b><Link href={`tel:${phone?.replace(' ', '')?.replace(' ', '')?.replace(' ', '')}`}>{phone}</Link></Typography>
              <Typography><b>Email: </b><Link href={`mailto:${email}`}>{email}</Link></Typography>
              <Typography><b>Adres: </b><Link href={`https://www.google.com/maps/place/${address}/@54.1953834,16.1894642,17z/data=!3m1!4b1!4m5!3m4!1s0x4701cd13bbf74b71:0x5b45fac2e58fc705!8m2!3d54.1953803!4d16.1920445?entry=ttu`}>{address}</Link>
              </Typography>
              <Typography sx={{ marginTop: '10px' }}><b>Godziny otwarcia:</b></Typography>
              <Typography>Pon - Pt: {hours.ponPt}</Typography>
              <Typography>Sobota: {hours.sob}</Typography>
              <Typography>Niedziela: {hours.nd}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography className="bold" variant="h2" sx={style.h3}>
                Informacje biznesowe
              </Typography>
              <Typography sx={{ marginBottom: '10px' }}><a href={regulation || '#'} target="_self">Regulamin serwisu</a></Typography>
              <Typography sx={{ marginBottom: '10px' }}><a href={methods || '#'} target="_self">Rodzaje metod płatności</a></Typography>
              <Typography sx={{ marginBottom: '10px' }}><a href={policy || '#'} target="_self">Polityka prywatności</a></Typography>
              <Typography sx={{ marginBottom: '10px' }}><a href={time || '#'} target="_self">Polityka czasu zamówienia</a></Typography>
              <Typography sx={{ marginBottom: '10px' }}><a href={reclamation || '#'} target="_self">Polityka reklamacji</a></Typography>
              <Typography sx={{ marginBottom: '10px' }}><a href={orders || '#'} target="_self">Regulamin odstąpienia od umowy</a></Typography>
            </Grid>
            <Grid item md={2} xs={12}>
              <Typography className="bold" variant="h2" sx={style.h3}>
                Social media
              </Typography>
              { facebook && <Typography sx={{ marginBottom: '10px' }}><a href={facebook} target="_blank" rel="noreferrer">Facebook</a></Typography> }
              { instagram && <Typography sx={{ marginBottom: '10px' }}><a href={instagram} target="_blank" rel="noreferrer">Instagram</a></Typography> }
              { youtube && <Typography sx={{ marginBottom: '10px' }}><a href={youtube} target="_blank" rel="noreferrer">Youtube</a></Typography> }
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography sx={{marginBottom: '20px'}}>
                @ {new Date().getFullYear()} wszelkie prawa zastrzeżone przez {company}. Strona stworzona
                przez &nbsp;
                <a href="https://your-site.pl" target="_blank" rel="noreferrer">Your&nbsp;site</a>.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </SizeWindow>
  )
}

export default Footer
