import Box from '@mui/material/Box'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'

const SizeWindow = (props) => {
  const { children, alignItems, justifyContent, minHeight } = props
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: alignItems && 'center',
        justifyContent: justifyContent || 'center',
        flexDirection: 'column',
        width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 100px)',
        minHeight: minHeight,
        position: 'relative',
        zIndex: 1,
        padding: BreakpointsHelper.isMobile() ? '15px' : '80px 50px 50px 50px',
        maxWidth: '1920px',
      }}
    >
      {children}
    </Box>
  )
}

export default SizeWindow
