import Netflix1 from '../../../assets/images/portfolio/19/1.png'
import FeedOctopus from '../../../assets/images/portfolio/6/1.png'
import PlanszowkiKoszalin from '../../../assets/images/portfolio/1/1.png'
import DrukarniaKadruk from '../../../assets/images/portfolio/2/1.webp'
import StudioKade from '../../../assets/images/portfolio/3/1.webp'
import AkademiaSztuki from '../../../assets/images/portfolio/4/1.webp'
import FundacjaLifeSurfers from '../../../assets/images/portfolio/5/1.webp'
import Tatiprofgaetano from '../../../assets/images/portfolio/7/1.webp'
import Mustic from '../../../assets/images/portfolio/34/1.webp'
import MarysiaSowinska from '../../../assets/images/portfolio/8/1.webp'
import CrystalUnity from '../../../assets/images/portfolio/9/1.webp'
import Comeko from '../../../assets/images/portfolio/10/1.webp'
import Granimar from '../../../assets/images/portfolio/11/1.webp'
import Sanrusen from '../../../assets/images/portfolio/12/1.webp'
import ComekoPlus from '../../../assets/images/portfolio/13/1.webp'
import KsiegowaCybulska from '../../../assets/images/portfolio/17/1.png'
import AplikacjaLifesurfers from '../../../assets/images/portfolio/16/1.png'
import HotFox from '../../../assets/images/portfolio/20/1.webp'
import Photler from '../../../assets/images/portfolio/22/1.webp'
import GIGnGIG from '../../../assets/images/portfolio/23/1.webp'
import HEHO1 from '../../../assets/images/portfolio/24/1.webp'
import HEHO2 from '../../../assets/images/portfolio/25/1.webp'
import Atmoterm from '../../../assets/images/portfolio/26/1.webp'
import Neeko1 from '../../../assets/images/portfolio/27/1.webp'
import Neeko2 from '../../../assets/images/portfolio/28/1.webp'
import ProArt1 from '../../../assets/images/portfolio/29/1.webp'
import ProArt2 from '../../../assets/images/portfolio/30/1.webp'
import RushCafe from '../../../assets/images/portfolio/31/1.webp'
import Wigo1 from '../../../assets/images/portfolio/32/1.webp'
import Wigo2 from '../../../assets/images/portfolio/33/1.webp'
import UnrealEngine from '../../../assets/images/portfolio/35/1.webp'
import BeautyCareLogo from '../../../assets/images/portfolio/36/1.webp'
import BeautyCareGraphic from '../../../assets/images/portfolio/37/1.webp'
import Bieszczadzka from '../../../assets/images/portfolio/38/1.webp'
import SocialImpaktLogo from '../../../assets/images/portfolio/39/1.webp'
import SocialImpaktGraphic from '../../../assets/images/portfolio/40/1.webp'
import PhotlerLogo from '../../../assets/images/portfolio/41/1.webp'
import AKMLogo from '../../../assets/images/portfolio/43/1.png'
import AKMWebsite from '../../../assets/images/portfolio/44/1.png'
import AKMGraphics from '../../../assets/images/portfolio/45/1.png'
import AquaBreeze from '../../../assets/images/portfolio/48/1.jpg'
import ArkaNieruchomosciLogo from '../../../assets/images/portfolio/49/1.png'
import ArkaNieruchomosciWebsite from '../../../assets/images/portfolio/85/1.png'
import Edekoratornia from '../../../assets/images/portfolio/52/1.png'
import ZamowFronty from '../../../assets/images/portfolio/54/1.png'
import FundacjaSportiNatura from '../../../assets/images/portfolio/55/1.png'
import GabinetTerapii from '../../../assets/images/portfolio/56/1.png'
import GartArt from '../../../assets/images/portfolio/57/1.png'
import Generic from '../../../assets/images/portfolio/58/1.png'
import JPPGroup from '../../../assets/images/portfolio/62/1.jpg'
import Kefik from '../../../assets/images/portfolio/63/1.png'
import MakKonopie from '../../../assets/images/portfolio/65/1.jpg'
import MassageMission from '../../../assets/images/portfolio/66/1.png'
import Mechanical from '../../../assets/images/portfolio/67/1.png'
import WielkiPowrotMecz from '../../../assets/images/portfolio/68/1.png'
import Medical from '../../../assets/images/portfolio/69/1.png'
import OSPMscice from '../../../assets/images/portfolio/71/1.png'
import OtherlandLabs from '../../../assets/images/portfolio/72/1.png'
import Quantum from '../../../assets/images/portfolio/74/1.png'
import Rehactive from '../../../assets/images/portfolio/75/1.png'
import Borowka from '../../../assets/images/portfolio/77/1.png'
import OPsachIKotach from '../../../assets/images/portfolio/78/1.png'
import SzeptemKonia from '../../../assets/images/portfolio/79/1.png'
import TasteWeeks from '../../../assets/images/portfolio/80/1.png'
import TUESLogo from '../../../assets/images/portfolio/81/1.png'
import TUESWebsite from '../../../assets/images/portfolio/89/1.png'
import WizualizacjaDomu from '../../../assets/images/portfolio/82/1.jpg'
import Hrlink from '../../../assets/images/portfolio/83/2.webp'
import EasyYachting from '../../../assets/images/portfolio/84/1.png'
import GlocalOne from '../../../assets/images/portfolio/87/1.png'
import VTS from '../../../assets/images/portfolio/88/1.png'
import ProjektAranzacyjny from '../../../assets/images/portfolio/90/1.png'
import Scale from '../../../assets/images/portfolio/91/1.png'
import KancelariaPrawnicza from '../../../assets/images/portfolio/92/1.png'
import AplikacjaFirmowa from '../../../assets/images/portfolio/93/1.png'
import MedicalCare from '../../../assets/images/portfolio/94/1.png'
import BanThaiSpa from '../../../assets/images/portfolio/95/1.png'
import UnityEngine from '../../../assets/images/portfolio/96/1.png'
import Lumion3D from '../../../assets/images/portfolio/97/6.jpg'
import PhotoshopProjektFacebook from '../../../assets/images/portfolio/98/1.png'
import PhotoshopProjektLew from '../../../assets/images/portfolio/99/1.png'
import PhotoshopProjektSzklo from '../../../assets/images/portfolio/100/1.jpg'
import VRaySketchup from '../../../assets/images/portfolio/101/1.jpg'
import data from '@emoji-mart/data'

export const portfolioData = [
  {
    id: 19,
    img: Netflix1,
    title: 'Netflix',
    subtitle: 'Filmy i Seriale',
    category: 'Aplikacje webowe',
  },
  {
    id: 6,
    img: FeedOctopus,
    title: 'Feed Octopus',
    subtitle: 'Feed Management',
    category: 'Aplikacje webowe',
  },
  {
    id: 1,
    img: PlanszowkiKoszalin,
    title: 'Planszówki Koszalin',
    subtitle: 'Grupa Planszówkowa',
    category: 'Aplikacje webowe',
  },
  {
    id: 2,
    img: DrukarniaKadruk,
    title: 'Drukarnia Kadruk',
    subtitle: 'Drukarnia internetowa',
    category: 'Aplikacje webowe',
  },
  {
    id: 3,
    img: StudioKade,
    title: 'Studio Kade',
    subtitle: 'Studio projektowe',
    category: 'Strony internetowe',
  },
  {
    id: 4,
    img: AkademiaSztuki,
    title: 'Akademia Sztuki',
    subtitle: 'Uczelnia',
    category: 'Strony internetowe',
  },
  {
    id: 5,
    img: FundacjaLifeSurfers,
    title: 'Fundacja Life Surfers',
    subtitle: 'Fundacja',
    category: 'Strony internetowe',
  },
  {
    id: 7,
    img: Tatiprofgaetano,
    title: 'Tatiprofgaetano',
    subtitle: 'Lekarz Urologii',
    category: 'Strony internetowe',
  },
  {
    id: 34,
    img: Mustic,
    title: 'Mustic',
    subtitle: 'Odtwarzacz muzyki',
    category: 'Aplikacje mobilne',
  },
  {
    id: 8,
    img: MarysiaSowinska,
    title: 'Marysia Sowińska',
    subtitle: 'Malarstwo i Fotografia',
    category: 'Strony internetowe',
  },
  {
    id: 9,
    img: CrystalUnity,
    title: 'Crystal Unity',
    subtitle: 'Mistrz Bioterapii',
    category: 'Strony internetowe',
  },
  {
    id: 10,
    img: Comeko,
    title: 'Comeko',
    subtitle: 'Fotowoltaika',
    category: 'Strony internetowe',
  },
  {
    id: 11,
    img: Granimar,
    title: 'Granimar',
    subtitle: 'Usługi kamiennicze',
    category: 'Strony internetowe',
  },
  {
    id: 12,
    img: Sanrusen,
    title: 'Sanrusen',
    subtitle: 'Logistyka',
    category: 'Strony internetowe',
  },
  {
    id: 13,
    img: ComekoPlus,
    title: 'Comeko Plus',
    subtitle: 'Fotowoltaika',
    category: 'Strony internetowe',
  },
  {
    id: 17,
    img: KsiegowaCybulska,
    title: 'Księgowa Cybulska',
    subtitle: 'Biuro Rachunkowe',
    category: 'Strony internetowe',
  },
  {
    id: 16,
    img: AplikacjaLifesurfers,
    title: 'Aplikacja Lifesurfers',
    subtitle: 'Fundacja',
    category: 'Aplikacje webowe',
  },
  {
    id: 20,
    img: HotFox,
    title: 'Hot Fox',
    subtitle: 'Gra komputerowa',
    category: 'Logo',
  },
  {
    id: 22,
    img: Photler,
    title: 'Photler',
    subtitle: 'Administracja',
    category: 'Projekty graficzne',
  },
  {
    id: 23,
    img: GIGnGIG,
    title: 'GIG & GIG',
    subtitle: 'Koncerty muzyczne',
    category: 'Logo',
  },
  {
    id: 24,
    img: HEHO1,
    title: 'HEHO',
    subtitle: 'DJ',
    category: 'Logo',
  },
  {
    id: 25,
    img: HEHO2,
    title: 'HEHO',
    subtitle: 'DJ',
    category: 'Projekty graficzne',
  },
  {
    id: 26,
    img: Atmoterm,
    title: 'Atmoterm',
    subtitle: 'Ochrona Środowiska',
    category: 'Logo',
  },
  {
    id: 27,
    img: Neeko1,
    title: 'Neeko',
    subtitle: 'Usługi czyszczące',
    category: 'Logo',
  },
  {
    id: 28,
    img: Neeko2,
    title: 'Neeko',
    subtitle: 'Usługi czyszczące',
    category: 'Projekty graficzne',
  },
  {
    id: 29,
    img: ProArt1,
    title: 'proArt',
    subtitle: 'Agencja Social Media',
    category: 'Logo',
  },
  {
    id: 30,
    img: ProArt2,
    title: 'proArt',
    subtitle: 'Agencja Social Media',
    category: 'Projekty graficzne',
  },
  {
    id: 31,
    img: RushCafe,
    title: 'Rush Cafe',
    subtitle: 'Kawiarnia',
    category: 'Projekty graficzne',
  },
  {
    id: 32,
    img: Wigo1,
    title: 'Wigo',
    subtitle: 'Naturalna woda',
    category: 'Logo',
  },
  {
    id: 33,
    img: Wigo2,
    title: 'Wigo',
    subtitle: 'Naturalna woda',
    category: 'Projekty graficzne',
  },
  {
    id: 35,
    img: UnrealEngine,
    title: 'Unreal Engine',
    subtitle: 'Świat gry 3D',
    category: 'Gra komputerowa',
  },
  {
    id: 36,
    img: BeautyCareLogo,
    title: 'Beauty Care',
    subtitle: 'Salon piękności',
    category: 'Logo',
  },
  {
    id: 37,
    img: BeautyCareGraphic,
    title: 'Beauty Care',
    subtitle: 'Salon piękności',
    category: 'Projekty graficzne',
  },
  {
    id: 38,
    img: Bieszczadzka,
    title: 'Bieszczadzka',
    subtitle: 'Szkoła rzemiosła',
    category: 'Logo',
  },
  {
    id: 39,
    img: SocialImpaktLogo,
    title: 'Social Impakt',
    subtitle: 'Woda gazowana',
    category: 'Logo',
  },
  {
    id: 40,
    img: SocialImpaktGraphic,
    title: 'Social Impakt',
    subtitle: 'Woda gazowana',
    category: 'Projekty graficzne',
  },
  {
    id: 41,
    img: PhotlerLogo,
    title: 'Photler',
    subtitle: 'Administracja',
    category: 'Logo',
  },
  {
    id: 43,
    img: AKMLogo,
    title: 'AKM',
    subtitle: 'Finanse i Ubezpieczenia',
    category: 'Logo',
  },
  {
    id: 44,
    img: AKMWebsite,
    title: 'AKM',
    subtitle: 'Finanse i Ubezpieczenia',
    category: 'Strony internetowe',
  },
  {
    id: 45,
    img: AKMGraphics,
    title: 'AKM',
    subtitle: 'Finanse i Ubezpieczenia',
    category: 'Projekty graficzne',
  },
  {
    id: 48,
    img: AquaBreeze,
    title: 'Aqua Breeze',
    subtitle: 'Kosmetyki',
    category: 'Projekty graficzne',
  },
  {
    id: 49,
    img: ArkaNieruchomosciLogo,
    title: 'Arka Nieruchomości',
    subtitle: 'Agent Nieruchomość',
    category: 'Logo',
  },
  {
    id: 85,
    img: ArkaNieruchomosciWebsite,
    title: 'Arka Nieruchomości',
    subtitle: 'Agent Nieruchomość',
    category: 'Strony internetowe',
  },
  {
    id: 52,
    img: Edekoratornia,
    title: 'E-dekoratornia',
    subtitle: 'Architektura wnętrz',
    category: 'Logo',
  },
  {
    id: 54,
    img: ZamowFronty,
    title: 'Zamów Fronty',
    subtitle: 'Architektura wnętrz',
    category: 'Logo',
  },
  {
    id: 55,
    img: FundacjaSportiNatura,
    title: 'Fundacja Sport i Natura',
    subtitle: 'Fundacja',
    category: 'Logo',
  },
  {
    id: 56,
    img: GabinetTerapii,
    title: 'Gabinet Terapii i Rozwoju',
    subtitle: 'Terapia',
    category: 'Projekty graficzne',
  },
  {
    id: 57,
    img: GartArt,
    title: 'Gart Art',
    subtitle: 'Ogrodniczy',
    category: 'Logo',
  },
  {
    id: 58,
    img: Generic,
    title: 'Generic',
    subtitle: 'Strony internetowe',
    category: 'Logo',
  },
  {
    id: 62,
    img: JPPGroup,
    title: 'JPP GROUP',
    subtitle: 'Technologie sieciowe',
    category: 'Logo',
  },
  {
    id: 63,
    img: Kefik,
    title: 'Kefik',
    subtitle: 'Profil Youtube',
    category: 'Projekty graficzne',
  },
  {
    id: 65,
    img: MakKonopie,
    title: 'Mak i Konopie',
    subtitle: 'Zioła relaksacyjne',
    category: 'Logo',
  },
  {
    id: 66,
    img: MassageMission,
    title: 'Massage Mission',
    subtitle: 'Masaże',
    category: 'Projekty graficzne',
  },
  {
    id: 67,
    img: Mechanical,
    title: 'Mechanical',
    subtitle: 'Mechanik',
    category: 'Logo',
  },
  {
    id: 68,
    img: WielkiPowrotMecz,
    title: 'Wielki Powrót Mecz',
    subtitle: 'Logo wydarzenia',
    category: 'Logo',
  },
  {
    id: 69,
    img: Medical,
    title: 'Medical',
    subtitle: 'Sprzęt Medyczny',
    category: 'Strony internetowe',
  },
  {
    id: 71,
    img: OSPMscice,
    title: 'OSP Mścice',
    subtitle: 'Ochotnicza straż pożarna',
    category: 'Aplikacje webowe',
  },
  {
    id: 72,
    img: OtherlandLabs,
    title: 'Otherland Labs',
    subtitle: 'Ochrona Środowiska',
    category: 'Logo',
  },
  {
    id: 74,
    img: Quantum,
    title: 'Quantum',
    subtitle: 'Terapia',
    category: 'Logo',
  },
  {
    id: 75,
    img: Rehactive,
    title: 'Rehactive',
    subtitle: 'Fizjoterapia',
    category: 'Strony internetowe',
  },
  {
    id: 77,
    img: Borowka,
    title: 'Borówka',
    subtitle: 'Zbieranie z krzaków',
    category: 'Projekty graficzne',
  },
  {
    id: 78,
    img: OPsachIKotach,
    title: 'O psach i kotach',
    subtitle: 'Zwierzęta',
    category: 'Logo',
  },
  {
    id: 79,
    img: SzeptemKonia,
    title: 'Szeptem konia',
    subtitle: 'Blog o koniach',
    category: 'Logo',
  }
]

export default portfolioData
