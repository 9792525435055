import {Grid, Button, Typography, Box, Paper} from '@mui/material'
import useStore from '../../store'
import {getAllDataFromPath} from '../../firebase'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import SizeWindow from '../../components/atoms/SizeWindow/SizeWindow'
import logo from '../../assets/images/logo.png'

const ProductPage = () => {
  const {id} = useParams()
  const {enqueueSnackbar} = useSnackbar()
  const addToCart = useStore((state) => state.addToCart)
  const [product, setProduct] = useState(null)
  const handleAddToCart = () => {
    addToCart(product)
    enqueueSnackbar('Dodano produkt do koszyka.', {variant: 'success'})
  }
  const fetchProduct = async () => {
    const productData = await getAllDataFromPath('tournaments')
    const foundProduct = productData.find((item) => item?.id === id)
    if (foundProduct) {
      setProduct(foundProduct.data)
    }
  }
  useEffect(() => {
    if (id) {
      fetchProduct()
    }
  }, [id])
  return (
    <SizeWindow>
      <Grid container spacing={4} sx={{padding: '20px'}}>
        <Grid item md={6} xs={12}>
          <Paper elevation={3} sx={{padding: '20px', textAlign: 'center'}}>
            {
              product?.image ? (
                <>
                  <img
                    src={product?.image?.[0]}
                    alt={product?.name}
                    style={{maxWidth: '100%', height: 'auto', borderRadius: '8px', minHeight: '400px'}}
                  />
                  <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                    {product?.image?.map((imgSrc, index) => (
                      <img
                        key={index}
                        src={imgSrc}
                        alt={product?.name}
                        style={{
                          width: '50px',
                          height: '50px',
                          marginRight: '10px',
                          borderRadius: '4px',
                          cursor: 'pointer'
                        }}
                      />
                    ))}
                  </Box>
                </>
              ) : (
                <img
                  src={logo}
                  alt={product?.name}
                  style={{maxWidth: '100%', height: 'auto', borderRadius: '8px', minHeight: '400px'}}
                />
              )
            }

          </Paper>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <Typography variant="h6" sx={{color: '#5dcc0d'}}>
              Produkt na stanie
            </Typography>
            <Typography variant="h4" sx={{fontWeight: 'bold'}}>{product?.name}</Typography>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <Button
                variant="contained"
                sx={{padding: '12px', fontSize: '16px', flex: 1, marginRight: '50px' }}
                onClick={() => handleAddToCart()}
              >
                Dodaj do koszyka
              </Button>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="h5"
                  sx={{textDecoration: 'line-through', color: '#999', marginRight: '10px'}}
                >
                  {(Number(product?.regularPrice) * 1.23).toFixed(2)} zł
                </Typography>
                <Typography variant="h4" sx={{fontWeight: '600', color: '#2c296a'}}>
                  {(Number(product?.regularPrice) * 0.75).toFixed(2)} zł
                </Typography>
              </Box>
            </Box>
            <Box dangerouslySetInnerHTML={{__html: product?.description}} sx={{textAlign: 'justify', color: '#777'}}/>
          </Box>
        </Grid>
      </Grid>
    </SizeWindow>
  )
}

export default ProductPage
