import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import DescriptionIcon from '@mui/icons-material/Description'
import {Grid, Box, Button, Typography, useTheme} from '@mui/material'
import slider from '../../../assets/images/hero-2.png'
import sliderWebP from '../../../assets/images/hero-2.webp'
import Image from '../Image/Image'
import Dot from '../Dot/Dot'
import {useHistory} from 'react-router'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import {PictureAsPdf, PictureAsPdfOutlined} from '@mui/icons-material'

const Slider = () => {
  const theme = useTheme()
  const seo = theme.config.seo
  const {company, smallDescription} = seo
  const {main} = theme.palette.primary
  const history = useHistory()
  const style = {
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: BreakpointsHelper.isMobile() ? '30px 0' : '100px 0',
      flexDirection: 'column'
    },
    image: {
      maxWidth: '100%',
      width: 'auto',
      borderRadius: '50px',
      maxHeight: '600px',
      minHeight: BreakpointsHelper.isMobile() ? 'none' : '480px',
      objectFit: 'cover'
    },
    h1: {
      fontSize: '52px',
      fontWeight: '600'
    },
    h2: {
      fontSize: '22px',
      fontWeight: '400',
      marginTop: '20px',
      maxWidth: '720px'
    },
    h3: {
      fontSize: '38px',
      fontWeight: '600',
      marginBottom: '10px',
      color: main
    },
    iconLinks: {
      display: 'flex',
      alignItems: 'center',
      gap: '15px',
      marginTop: '30px',
      flexWrap: 'wrap',
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      color: 'inherit',
      textDecoration: 'none',
      fontSize: '16px',
      marginRight: '20px',
    },
    icon: {
      marginRight: '8px',
      color: '#2c296a',
    },
    iconFB: {
      marginRight: '8px',
      color: '#1950da',
    },
    iconLN: {
      marginRight: '8px',
      color: '#1da1f2',
    },
    iconPDF: {
      marginRight: '8px',
      color: 'red',
    },
  }

  return (
    <Box sx={style.root}>
      <Grid container spacing={8}>
        <Grid item md={6} xs={12} sx={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'center'}}>
          <Typography variant="h1" sx={style.h3}>
            Najwyższy standard z
          </Typography>
          <Typography variant="h2" sx={style.h1}>
            {company}
            <Dot />
          </Typography>
          <Typography sx={style.h2}>
            {smallDescription}
          </Typography>
          <Box sx={{display: 'flex', marginTop: '20px'}}>
            <Button
              sx={{marginRight: '20px', color: '#023a51', borderRadius: '3px', height: '46px'}}
              onClick={() => history.push('/shop')}
              variant="outlined"
            >
              Przejdź do sklepu
            </Button>
            <Button
              sx={{marginRight: '20px', color: 'white', borderRadius: '3px', height: '46px'}}
              onClick={() => window.open('https://calendly.com/yoursitepoland/spotkanie', '_blank')}
              variant="contained"
            >
              Umów spotkanie
            </Button>
          </Box>
          <Box sx={style.iconLinks}>
            <a href="https://www.facebook.com/YourPage" target="_blank" rel="noopener noreferrer" style={style.link}>
              <FacebookIcon sx={style.iconFB} /> Facebook
            </a>
            <a href="https://www.linkedin.com/in/YourProfile" target="_blank" rel="noopener noreferrer" style={style.link}>
              <LinkedInIcon sx={style.iconLN} /> Linkedin
            </a>
            <a href="/path/to/cv_pl.pdf" target="_blank" rel="noopener noreferrer" style={style.link}>
              <PictureAsPdf sx={style.iconPDF} /> CV - pl
            </a>
            <a href="/path/to/cv_en.pdf" target="_blank" rel="noopener noreferrer" style={style.link}>
              <PictureAsPdf sx={style.icon} /> CV - en
            </a>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Image
              srcWebP={sliderWebP}
              src={slider}
              alt="slider image"
              style={style.image}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Slider
