import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import useStore from '../../store'
import {useEffect, useState} from 'react'
import {Grid, useTheme} from '@mui/material'
import Seo from '../../components/molecules/Seo/Seo'
import SizeWindow from '../../components/atoms/SizeWindow/SizeWindow'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import {updateOrCreateDocument} from '../../firebase'
import StringHelper from '../../helpers/StringHelper/StringHelper'
import GoogleServices from '../../services/GoogleServices/GoogleServices'
import SmallProduct from '../../components/molecules/SmallProduct/SmallProduct'
import {useHistory} from 'react-router'

const CheckoutPage = () => {
  const theme = useTheme()
  const seo = theme.config.seo
  const history = useHistory()
  const {url, company, email, phone, address, city, region, postalCode} = seo
  const minicart = useStore((state) => state.miniCart)
  const setStep = useStore((state) => state.setStep)
  const [price, setPrice] = useState(0)
  const [cartInfo, setCartInfo] = useState(LocalStorageHelper.get('cartInfo'))
  const [dataAddress, setDataAddress] = useState(null)
  const formData = {
    elements: [
      {
        name: 'name',
        type: 'text',
        label: 'Imię i nazwisko',
      },
      {
        name: 'email',
        type: 'email',
        label: 'Email',
      },
      {
        name: 'phone',
        type: 'tel',
        label: 'Telefon',
      },
      {
        name: 'address',
        type: 'text',
        label: 'Ulica z numerem domu / mieszkania',
      },
      {
        name: 'city',
        type: 'text',
        label: 'Miasto',
      },
      {
        name: 'region',
        type: 'select',
        items: [
          { value: 'dolnoslaskie', name: 'Dolnośląskie' },
          { value: 'kujawsko-pomorskie', name: 'Kujawsko-Pomorskie' },
          { value: 'lubelskie', name: 'Lubelskie' },
          { value: 'lubuskie', name: 'Lubuskie' },
          { value: 'lodzkie', name: 'Łódzkie' },
          { value: 'malopolskie', name: 'Małopolskie' },
          { value: 'mazowieckie', name: 'Mazowieckie' },
          { value: 'opolskie', name: 'Opolskie' },
          { value: 'podkarpackie', name: 'Podkarpackie' },
          { value: 'podlaskie', name: 'Podlaskie' },
          { value: 'pomorskie', name: 'Pomorskie' },
          { value: 'slaskie', name: 'Śląskie' },
          { value: 'swietokrzyskie', name: 'Świętokrzyskie' },
          { value: 'warminsko-mazurskie', name: 'Warmińsko-Mazurskie' },
          { value: 'wielkopolskie', name: 'Wielkopolskie' },
          { value: 'zachodniopomorskie', name: 'Zachodniopomorskie' }
        ],
        label: 'Województwo'
      },
      {
        name: 'nip',
        type: 'text',
        label: 'NIP',
        helperText: 'Opcjonalnie podaj nip jeżeli potrzebujesz faktury VAT. Dane do faktury pobierane są automatycznie. Jeżeli potrzebujesz innych danych na fakturze prosimy o kontakt w tej sprawie.',
        required: false,
      },
      {
        type: 'button',
        value: dataAddress ? 'Edytuj dane' : 'Zapisz dane'
      }
    ]
  }
  const getAllPrices = () => {
    let tempPrice = 0
    minicart?.items?.forEach((item) => {
      tempPrice += Number(item.regularPrice)
    })
    setPrice(tempPrice)
  }
  const createNumberOrder = async (e) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    const cartInfo = LocalStorageHelper.get('cartInfo') || {}
    const code = await GoogleServices.getPostalCode(`${e?.address}, ${e?.city}, ${e?.region}, Polska`)
    cartInfo.name = e.name
    cartInfo.email = e.email
    cartInfo.phone = e.phone
    cartInfo.address = e.address
    cartInfo.city = e.city
    cartInfo.region = e.region
    cartInfo.postalCode = code
    setCartInfo(cartInfo)
    LocalStorageHelper.set('cartInfo', cartInfo)
    setDataAddress(e)
  }
  const generateOrderNumber = () => {
    const now = new Date()
    const timestamp = now.getFullYear().toString() +
      String(now.getMonth() + 1).padStart(2, '0') +
      String(now.getDate()).padStart(2, '0') +
      String(now.getHours()).padStart(2, '0') +
      String(now.getMinutes()).padStart(2, '0') +
      String(now.getSeconds()).padStart(2, '0')
    const randomPart = Math.floor(Math.random() * 10000)
    return timestamp + randomPart.toString().padStart(4, '0')
  }
  const handleCheckout = () => {
    const orderId = generateOrderNumber()
    const generateMiniCart = () => {
      return minicart?.items?.map(item => ({
        name: item?.name,
        unitPrice: Math.round(Number(item?.regularPrice).toFixed(2) * 1.23 * 100),
        quantity: item?.quantity
      }))
    }
    const data = {
      idPos: process.env.REACT_APP_POS_ID,
      signatureKey: process.env.REACT_APP_MD52,
      oauthClientId: process.env.REACT_APP_POS_ID,
      oauthClientSecret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
      description: `Opłata: ${url}, Numer zamówienia: ${orderId}`,
      currencyCode: 'PLN',
      extOrderId: `${orderId}`,
      totalAmount: Math.round(Number(price).toFixed(2) * 100),
      buyer: {
        email: cartInfo?.email,
        phone: cartInfo?.phone,
        firstName: cartInfo?.name.split(' ')?.[0],
        lastName: cartInfo?.name?.split(' ')?.[1],
        language: 'pl',
        delivery: {
          recipientName: `Klient firmy: ${company}`,
          recipientEmail: email,
          recipientPhone: phone,
          street: address?.split(',')[0],
          postalBox: cartInfo?.city,
          postalCode: cartInfo?.postalCode,
          city: cartInfo?.city,
          state: cartInfo?.region,
          countryCode: 'PL',
        },
      },
      products: generateMiniCart(),
      continueUrl: `https://your-site.pl/successOrder/${orderId}`,
      notifyUrl: `https://your-site.pl/raportOrder/${orderId}`,
    }
    const dataToSend = {
      cartInfo,
      cart: LocalStorageHelper.get('cart'),
      data,
    }
    fetch('https://api.your-site.pl/payu/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      cors: 'no-cors',
      body: JSON.stringify(data)
    }).then((response) => response.json())
      .then((res) => {
        if (res?.status === 'SUCCESS') {
          updateOrCreateDocument('orders', orderId, dataToSend).then(() => {
            if (res?.redirect_url) {
              window.open(res?.redirect_url, '_self')
            }
          })
        }
      }).catch((error) => {
        console.error('Error:', error)
      })
  }
  const style = {
    title: {
      marginBottom: '20px'
    },
  }
  const checkDisabled = () => {
    return !(cartInfo?.name && cartInfo?.email && cartInfo?.method && cartInfo?.phone && cartInfo?.address)
  }
  useEffect(() => {
    if (minicart?.items?.length > 0) {
      getAllPrices()
    } else {
      history.push('/cart')
    }
  }, [minicart])
  useEffect(() => {
    setStep(2)
  }, [])
  return (
    <SizeWindow>
      <Seo data={{'seo: title': `${seo.company} - Koszyk`}}/>
      <Typography variant="h5" sx={style.title}>Zamówienie</Typography>
      <Grid container spacing={8}>
        <Grid item md={8} xs={12}>
          <SmallProduct minicart={minicart} />
          <Typography sx={{ fontWeight: '600', margin: '20px 0' }}>Dane do zamówienia / wysyłki</Typography>
          <FormGenerator data={formData} submit={(e) => createNumberOrder(e)} />
        </Grid>
        <Grid item md={4} xs={12}>
          <Box sx={{width: '100%' }}>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <Typography sx={{ fontWeight: '600'}}>Podsumowanie</Typography>
              <Typography>{(Number(price) * 1.23).toFixed(2)} zl brutto</Typography>
            </Box>
            <Typography sx={{ textAlign: 'right', marginTop: '10px'}}>
              {Number(price).toFixed(2)} zl netto
            </Typography>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
              <Typography>Podatek</Typography>
              <Typography>23% VAT</Typography>
            </Box>
            {
              cartInfo && (
                <>
                  <Typography sx={{ fontWeight: '600', marginBottom: '20px' }}>Dane do zamówienia</Typography>
                  {
                    cartInfo?.method === 'online' ? (
                      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography>Metoda wysyłki</Typography>
                        <Typography>Usługa online</Typography>
                      </Box>
                    ) : cartInfo?.method === 'cod' ? (
                      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography>Wysyłka</Typography>
                        <Typography>Odbiór własny</Typography>
                      </Box>
                    ) : (
                      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography>Wysyłka</Typography>
                        <Typography>Inpost - paczkomat</Typography>
                      </Box>
                    )
                  }
                  {
                    cartInfo?.name && (
                      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography>Imię i nazwisko</Typography>
                        <Typography>{cartInfo?.name}</Typography>
                      </Box>
                    )
                  }
                  {
                    cartInfo?.email && (
                      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography>Email</Typography>
                        <Typography>{cartInfo?.email}</Typography>
                      </Box>
                    )
                  }
                  {
                    cartInfo?.phone && (
                      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography>Telefon</Typography>
                        <Typography>{cartInfo?.phone}</Typography>
                      </Box>
                    )
                  }
                  {
                    cartInfo?.address && (
                      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography>Adres</Typography>
                        <Typography>{cartInfo?.address}</Typography>
                      </Box>
                    )
                  }
                  {
                    cartInfo.postalCode && (
                      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography>Kod pocztowy</Typography>
                        <Typography>{cartInfo.postalCode}</Typography>
                      </Box>
                    )
                  }
                  {
                    cartInfo?.city && (
                      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography>Miasto</Typography>
                        <Typography>{cartInfo?.city}</Typography>
                      </Box>
                    )
                  }
                  {
                    cartInfo?.region && (
                      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography>Region</Typography>
                        <Typography>{StringHelper.toCapitalize(cartInfo?.region)}</Typography>
                      </Box>
                    )
                  }
                  <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Typography>Kraj</Typography>
                    <Typography>Polska</Typography>
                  </Box>
                </>
              )
            }
            <Button fullWidth variant="contained" sx={{ height: '40px', marginTop: '20px' }} onClick={() => minicart && handleCheckout()} disabled={checkDisabled()}>
              Zapłać za zamówienie
            </Button>
          </Box>
        </Grid>
      </Grid>
    </SizeWindow>
  )
}

export default CheckoutPage
