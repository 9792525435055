import React, { useState } from 'react'
import portfolioData from './data'
import './Portfolio.scss'
import Typography from '@mui/material/Typography'
import Dot from '../../atoms/Dot/Dot'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import Button from '@mui/material/Button'
import { Grid, Dialog, DialogContent } from '@mui/material'
import Box from '@mui/material/Box'

const Portfolio = () => {
  const [activeCategory, setActiveCategory] = useState('Wszystkie')
  const [open, setOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  const handleClickOpen = (img) => {
    setSelectedImage(img)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedImage(null)
  }

  const style = {
    title: {
      fontSize: '30px',
      fontWeight: '600',
      color: '#2c296a',
      marginBottom: '10px',
      textAlign: 'left'
    },
    small: {
      fontSize: BreakpointsHelper.isMobile() ? '31px' : '52px',
      fontWeight: '600',
      marginBottom: '30px',
      maxWidth: '720px',
      textAlign: 'left',
      margin: '0 auto 20px 0'
    },
    image: {
      width: '100%',
      height: '200px',
      maxHeight: '200px',
      objectFit: 'cover',
      cursor: 'pointer',
      transition: '.3s all',
    },
    dialogImage: {
      width: '100%',
      height: 'auto'
    },
    categories: {
      display: 'flex',
      gap: '10px',
      flexWrap: 'wrap',
      marginBottom: '20px',
    }
  }

  const filteredData = activeCategory === 'Wszystkie'
    ? portfolioData
    : portfolioData?.filter((project) => project.category === activeCategory) || []

  const categories = [
    'Wszystkie',
    'Aplikacje webowe',
    'Strony internetowe',
    'Aplikacje mobilne',
    'Logo',
    'Projekty graficzne',
    'Gra komputerowa'
  ]

  return (
    <div className="portfolio">
      <Typography variant="h1" sx={style.title}>Wybrane projekty portfolio</Typography>
      <Typography variant="h2" sx={style.small}>Bądź kreatywny w pracy<Dot/></Typography>
      <Box sx={style.categories}>
        {categories.map((category) => (
          <Button
            variant={activeCategory === category ? 'contained' : 'outlined'}
            key={category}
            className={activeCategory === category ? 'active' : ''}
            onClick={() => setActiveCategory(category)}
          >
            {category}
          </Button>
        ))}
      </Box>
      <Grid container spacing={4}>
        {
          filteredData?.map((project) => (
            <Grid item md={3} xs={12} key={project.id}>
              <Box className="portfolio__item">
                <img
                  src={project.img}
                  alt={project.title}
                  style={style.image}
                  onClick={() => handleClickOpen(project.img)}
                />
                <h3>{project.title}</h3>
                <p>{project.subtitle}</p>
                <span>{project.category}</span>
              </Box>
            </Grid>
          ))
        }
      </Grid>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogContent>
          {selectedImage && (
            <img src={selectedImage} alt="Full view" style={style.dialogImage} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Portfolio
