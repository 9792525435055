import {Button, Grid} from '@mui/material'
import {useSnackbar} from 'notistack'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import UserHelper from '../../helpers/UserHelper/UserHelper'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import StringHelper from '../../helpers/StringHelper/StringHelper'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import Seo from '../../components/molecules/Seo/Seo'
import {useTheme} from '@mui/styles'
import Box from '@mui/material/Box'
import background from '../../assets/images/pic1.png'

const RegisterPage = (props) => {
  const theme = useTheme()
  const t = LocalStorageHelper.get('translation')
  const {enqueueSnackbar} = useSnackbar()
  const formData = {
    elements: [
      {
        name: 'name',
        type: 'text',
        label: t.globals.inputs.firstName.label + ' i ' + t.globals.inputs.secondName.label,
        validationType: 'string'
      },
      {
        name: 'email',
        type: 'email',
        label: t.globals.inputs.email.label,
        validationType: 'string'
      },
      {
        name: 'password',
        type: 'password',
        label: t.globals.inputs.password.label,
        helperText: t.globals.inputs.password.helperText,
        validationType: 'string'
      },
      {
        name: 'regulations',
        type: 'checkbox',
        label: 'Akceptacja regulaminu oraz polityki prywatności dostępnego pod adresem .....',
        validationType: 'string'
      },
      {
        type: 'button',
        value: 'Zarejestruj'
      }
    ]
  }
  const style = {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      height: 'calc(100vh - 80px)',
    },
    image: {
      height: '100%',
      maxHeight: 'calc(100vh - 120px)',
      position: 'absolute',
      bottom: '0',
    },
    box: {
      background: 'white',
      fontSize: BreakpointsHelper.isMobile() ? '24px' : '25px',
      fontWeight: 700,
      padding: BreakpointsHelper.isMobile() ? '15px' : '50px',
      maxWidth: BreakpointsHelper.isMobile() ? 'none' : '320px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      border: '1px solid black',
    },
    desc: {
      maxWidth: '320px',
      fontSize: '16px',
      fontWeight: BreakpointsHelper.isMobile() ? '400' : '300',
      textTransform: 'none',
      textAlign: 'center',
      marginTop: '5px',
      marginBottom: '20px',
      color: '#5E626F',
    },
    button: {
      background: '#1b0f23',
      color: 'white',
      fontSize: '16px',
      fontWeight: 700,
      padding: '5px 25px',
      width: '100%',
      marginTop: '20px'
    },
    facebookButton: {
      display: 'none'
    },
    input: {
      marginBottom: '5px',
      marginTop: '5px'
    },
    inputFirst: {
      marginBottom: '5px',
      marginTop: '25px'
    }
  }
  const handleRegister = (e) => {
    e.firstName = StringHelper.toCapitalize(e.firstName)
    e.lastName = StringHelper.toCapitalize(e.lastName)
    UserHelper.register(e).then((res) => {
      if (res) enqueueSnackbar(t.registerPage.toasts.success, {variant: 'success'})
      else enqueueSnackbar(t.registerPage.toasts.error, {variant: 'error'})
    }).catch((error) => error)
  }
  return (
    <>
      <Seo data={{ 'seo: title': `${theme?.config?.seo?.company} - Rejestracja` }}/>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12} sx={{ background: '#f6f6f6' }}>
          <img src={background} alt={'background image login page'} style={style.image} />
        </Grid>
        <Grid item md={6} xs={12}>
          <Box sx={style.root}>
            <Box sx={style.box}>
              Rejestracja
              <div style={style.desc}>
                Utwórz konto podając dane ponieżej i wybierając przycisk zarejestruj.
              </div>
              <FormGenerator data={formData} submit={(e) => handleRegister(e)} {...props}/>
              <Button
                fullWidth
                variant="outlined"
                sx={{ maxWidth: '320px', minHeight: '40px' }}
                onClick={() => props.location.history.push('/login')}
              >
                Zaloguj
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default RegisterPage
