import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {Grid, useTheme} from '@mui/material'
import Slider from '../../components/atoms/Slider/Slider'
import Category from '../../components/atoms/Category/Category'
import Button from '@mui/material/Button'
import SizeWindow from '../../components/atoms/SizeWindow/SizeWindow'
import NewsPosts from '../../components/molecules/NewsPosts/NewsPosts'
import {useEffect, useState} from 'react'
import {getAllDataFromPath} from '../../firebase'
import Seo from '../../components/molecules/Seo/Seo'
import ContactForm from '../../components/molecules/ContactForm/ContactForm'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'

const HomePage = (props) => {
    const theme = useTheme()
    const seo = theme.config.seo
    const [categories, setCategories] = useState(null)
    const [articles, setArticles] = useState(null)
    const style = {
        h2: {
            fontSize: '42px',
            fontWeight: '600',
            textAlign: 'left',
            marginTop: '10px',
            width: '100%'
        },
        cat: {
            width: '60px',
            fill: '#2c296a',
            marginBottom: '20px',
        },
        title: {
            fontSize: BreakpointsHelper.isMobile() ? '30px' : '52px',
            fontWeight: '600',
            marginBottom: '30px',
            textAlign: 'center',
        },
        h2Center: {
            fontSize: '38px',
            fontWeight: '600',
            textAlign: 'center',
            marginTop: '10px',
            width: '100%',
            marginBottom: '30px',
        },
        h3: {
            fontSize: '22px',
            fontWeight: '400',
            marginTop: '10px',
            textAlign: 'center',
            width: '100%'
        },
        desc: {
            fontSize: '22px'
        },
        h3Topic: {
            fontSize: '22px',
            fontWeight: '400',
            marginTop: '10px',
            textAlign: BreakpointsHelper.isMobile() ? 'left' : 'left',
            width: '100%'
        },
        h3Center: {
            fontSize: '22px',
            fontWeight: '400',
            marginTop: '10px',
            textAlign: 'center',
            width: '100%'
        },
        h4: {
            fontSize: '28px',
            fontWeight: '600',
            textAlign: 'center',
            display: 'flex',
            margin: '0 auto',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px',
            width: '100%',
            marginBottom: '20px',
            minHeight: '80px'
        },
        image: {
            width: '100%',
            display: 'flex',
            maxWidth: '320px',
            marginBottom: '30px'
        },
        box: {
            display: 'flex',
            flexDirection: 'column',
            background: 'white',
            padding: '40px',
            borderRadius: '30px',
            minHeight: '350px',
            transition: '0.3s all',
            justifyContent: 'flex-start',
            cursor: 'pointer',
            '&:hover': {
                transform: 'scale(1.2)',
                transition: '0.3s all',
            }
        },
    }
    useEffect(() => {
        if (!articles) {
            getAllDataFromPath('articles').then((res) => {
                if (res) {
                    setArticles(res)
                }
            })
        } else {
            getAllDataFromPath('categories').then(async (res) => {
                if (res) {
                    const temp = []
                    res?.forEach(el => {
                        const data = el?.data
                        const id = el?.id
                        let count = 0
                        articles?.forEach(art => {
                            if (art?.data?.category === id) {
                                count++
                            }
                        })
                        temp.push({
                            name: data?.name,
                            image: data?.image?.length > 0 ? data?.image[0] : '',
                            link: `/showCategories/${id}`,
                            articles: count
                        })
                    })
                    await temp?.sort((el, next) => Number(next?.articles) - Number(el?.articles))
                    setCategories(temp)
                }
            })
        }
    }, [articles])
    useEffect(() => {
        // InfaktService.auth('/account/details.json').then((res) => {
        //     console.info(res)
        // })
    }, [])
    return (
        <>
            <Seo data={{'seo: title': `${seo.company} - Home`}}/>
            <SizeWindow>
                <Slider/>
            </SizeWindow>
            <SizeWindow>
                <Box sx={{margin: '100px auto', display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <Typography variant="h2" sx={style.h2}>
                                Najczęściej wybierane tematy
                            </Typography>
                            <Typography variant="h3" sx={style.h3Topic}>
                                Przegląd kluczowych zagadnień księgowych
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <Button
                                    fullWidth={BreakpointsHelper.isMobile()}
                                    sx={{
                                        marginRight: '20px',
                                        color: 'white',
                                        borderRadius: '3px',
                                        height: '46px'
                                    }}
                                    onClick={() => window.open('/showCategories', '_self')}
                                    variant="contained"
                                >
                                    Wszystkie dostępne kategorie
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        padding: '50px 0',
                        flexWrap: 'wrap'
                    }}
                >
                    {
                        categories?.length > 0 && categories?.map((el, index) => {
                            if (index < 4) {
                                return (
                                    <Category index={index} el={el} key={index}/>
                                )
                            }
                            return ''
                        })
                    }
                </Box>
                <NewsPosts {...props} />
                <ContactForm/>
            </SizeWindow>
        </>
    )
}

export default HomePage
