import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import './MagicLoader.scss'
import {useHistory} from 'react-router'
const MagicLoader = (props) => {
  const history = useHistory()
  const { children, callback, variable = true, time } = props
  const [isLoading, setIsLoading] = useState(true)
  const style = {
    root: {
      position: 'absolute',
      zIndex: '999',
      width: '100vw',
      height: '100vh',
      background: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }
  useEffect(() => {
    let timeLoad = null
    setIsLoading(true)
    if (!callback) {
      timeLoad = setTimeout(() => {
        if (variable) setIsLoading(false)
      }, time || 300)
    } else callback(setIsLoading)
    return () => clearTimeout(timeLoad)
  }, [variable, history?.location?.pathname])
  return (
    <>
      {
        isLoading
          ? (
            <Box className="MagicLoader" sx={style.root}>
              <div className="loader"></div>
            </Box>
          )
          : children
      }
    </>
  )
}

export default MagicLoader
