import {useTheme} from '@mui/styles'

const Dot = ({ char, background }) => {
  const theme = useTheme()
  const { main } = theme.palette.primary
  const style = {
    root: {
      color: background || main,
      marginLeft: '2px',
      fontWeight: '600',
    }
  }
  return (
    <span style={style.root}>{char || '.'}</span>
  )
}

export default Dot
