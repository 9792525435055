import React from 'react'
import { Route } from 'react-router-dom'

export const PublicRoute = ({ component: Component, layout: Layout, ...rest }) => (
	<Route
		{...rest}
		render={(props) => (
			<Layout component={Component} location={props.location} />
			)}
	/>
)
