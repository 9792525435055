import { create } from 'zustand'
import LocalStorageHelper from './helpers/LocalStorageHelper/LocalStorageHelper'

const useStore = create((set, get) => ({
  miniCart: { items: [] },
  step: 0,
  setStep: (number) => set(() => ({
    step: number,
  })),
  setMiniCart: (cart) => set(() => ({
    miniCart: {
      ...cart,
    }
  })),
  getMiniCart: () => get().miniCart,
  addToCart: (item) => set((state) => {
    const existingItemIndex = state?.miniCart?.items?.findIndex((cartItem) => cartItem.id === item.id)
    if (existingItemIndex !== -1) {
      const updatedItems = state?.miniCart?.items?.map((cartItem, index) =>
        index === existingItemIndex
          ? { ...cartItem, quantity: cartItem.quantity + 1 }
          : cartItem
      )

      return {
        miniCart: {
          ...state.miniCart,
          items: updatedItems,
        },
      }
    } else {
      return {
        miniCart: {
          ...state.miniCart,
          items: [...state.miniCart.items, { ...item, quantity: 1 }],
        },
      }
    }
  }),
  removeFromCart: (itemId) => set((state) => {
    LocalStorageHelper.set('cart', ({
        ...state.miniCart,
        items: state.miniCart.items.filter(item => item.id !== itemId),
    }))
    return ({
      miniCart: {
        ...state.miniCart,
        items: state.miniCart.items.filter(item => item.id !== itemId),
      }
    })
  }),
  clearCart: () => set(() => {
    LocalStorageHelper.remove('cart')
    LocalStorageHelper.remove('cartInfo')
    return ({
      miniCart: { items: [] }
    })
  }),
}))

export default useStore
