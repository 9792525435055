import {BrowserRouter as Router, Switch} from 'react-router-dom'
import {Redirect} from 'react-router'
import './assets/scss/App.scss'
import {PublicRoute} from './router/PublicRoute/PublicRoute'
import LoginPage from './pages/LoginPage/LoginPage'
import SimpleLayout from './layouts/SimpleLayout/SimpleLayout'
import {PrivateRoute} from './router/PrivateRoute/PrivateRoute'
import HomePage from './pages/HomePage/HomePage'
import PreviewLayout from './layouts/PreviewLayout/PreviewLayout'
import RegisterPage from './pages/RegisterPage/RegisterPage'
import ProfilePage from './pages/ProfilePage/ProfilePage'
import AdminPage from './pages/AdminPage/AdminPage'
import RememberPage from './pages/RememberPage/RememberPage'
import LangHelper from './helpers/LangHelper/LangHelper'
import PreviewArticle from './pages/PreviewArticlePage/PreviewArticlePage'
import ContactPage from './pages/ContactPage/ContactPage'
import ShowCategoriesPage from './pages/ShowCategories/ShowCategoriesPage'
import EditSilosPage from './pages/EditSilosPage/EditSilosPage'
import EditCategoryPage from './pages/EditCategoryPage/EditCategoryPage'
import EditTagPage from './pages/EditTagPage/EditTagPage'
import EditArticlePage from './pages/EditArticlePage/EditArticlePage'
import AboutPage from './pages/AboutPage/AboutPage'
import ShopPage from './pages/ShopPage/ShopPage'
import ProductPage from './pages/ProductPage/ProductPage'
import CartPage from './pages/CartPage/CartPage'
import CheckoutPage from './pages/CheckoutPage/CheckoutPage'
import SuccessPage from './pages/SuccessPage/SuccessPage'
import TagManager from 'react-gtm-module'
import {useEffect} from 'react'
import PortfolioPage from './pages/PortfolioPage/PortfolioPage'

const App = () => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_KEY,
    dataLayerName: 'PageDataLayer',
  }
  useEffect(() => {
    LangHelper.setDefaultLanguage()
    TagManager.initialize(tagManagerArgs)
  }, [])
  return (
    <Router basename="/">
      <Switch>
        <PublicRoute exact path={'/'} component={() => <Redirect to={'/home'}/>} layout={SimpleLayout}/>
        <PublicRoute path={'/home'} component={HomePage} layout={PreviewLayout}/>
        <PublicRoute path={'/about'} component={AboutPage} layout={PreviewLayout}/>
        <PublicRoute path={'/login'} component={LoginPage} layout={PreviewLayout}/>
        <PublicRoute path={'/register'} component={RegisterPage} layout={PreviewLayout}/>
        <PublicRoute path={'/reset'} component={RememberPage} layout={PreviewLayout}/>
        <PublicRoute path={'/portfolio'} component={PortfolioPage} layout={PreviewLayout}/>
        <PublicRoute path={'/product/:id'} component={ProductPage} layout={PreviewLayout}/>
        <PublicRoute path={'/showArticle/:id'} component={PreviewArticle} layout={PreviewLayout}/>
        <PublicRoute path={'/showCategories/:id'} component={ShowCategoriesPage} layout={PreviewLayout}/>
        <PublicRoute path={'/contact'} component={ContactPage} layout={PreviewLayout}/>
        <PublicRoute path={'/shop'} component={ShopPage} layout={PreviewLayout}/>
        <PublicRoute path={'/cart'} component={CartPage} layout={PreviewLayout}/>
        <PublicRoute path={'/checkout'} component={CheckoutPage} layout={PreviewLayout}/>
        <PublicRoute path={'/successOrder/:id'} component={SuccessPage} layout={PreviewLayout}/>
        <PrivateRoute path={'/admin'} component={AdminPage} layout={PreviewLayout}/>
        <PrivateRoute path={'/profile'} component={ProfilePage} layout={PreviewLayout}/>
        <PrivateRoute path={'/editSilos/:id'} component={EditSilosPage} layout={PreviewLayout}/>
        <PrivateRoute path={'/editCategory/:id'} component={EditCategoryPage} layout={PreviewLayout}/>
        <PrivateRoute path={'/editTag/:id'} component={EditTagPage} layout={PreviewLayout}/>
        <PrivateRoute path={'/editArticle/:id'} component={EditArticlePage} layout={PreviewLayout}/>
      </Switch>
    </Router>
  )
}

export default App
