import Page from '../../components/molecules/Page/Page'
import Typography from '@mui/material/Typography'
import Seo from '../../components/molecules/Seo/Seo'
import AboutImage from '../../assets/images/about.jpg'
import {Grid, useTheme} from '@mui/material'
import ContactBanner from '../../components/molecules/ContactBanner/ContactBanner'
import google from '../../assets/images/google.png'
import bbc from '../../assets/images/bbc.png'
import netflix from '../../assets/images/netflix.png'
import wedel from '../../assets/images/wedel.png'
import hrlink from '../../assets/images/hrlink.png'
import morizon from '../../assets/images/morizon.png'
import Box from '@mui/material/Box'

const AboutPage = () => {
  const theme = useTheme()
  const seo = theme.config.seo
  const style = {
    title: {
      fontSize: '26px',
      fontWeight: '600'
    },
    desc: {
      fontSize: '22px',
      textAlign: 'justify'
    },
    box: {
      marginTop: '40px'
    },
    images: {
      height: '50px',
      maxWidth: '100%',
      objectFit: 'contain',
      marginBottom: '10px'
    }
  }
  return (
    <>
      <Seo data={{'seo: title': `${seo.company} - O nas`}}/>
      <Page
        image={AboutImage}
        title={'O nas'}
        small={'Profesjonalne Usługi Webowe'}
        description={(
          <>
            <Typography sx={style.desc}>
              Gotowy, by zamienić twój pomysł w realizację! Jestem doświadczonym specjalistą w dziedzinie Technologii
              Sieciowych, z główną specjalizacją w tworzeniu aplikacji webowych. Moja profesjonalna podróż rozpoczęła
              się w 2010 roku, a obecnie pełnię rolę Senior Frontend Developera.
            </Typography>
            <Typography sx={style.desc}>
              Moje międzynarodowe doświadczenie zawodowe pozwoliło mi na współpracę z prestiżowymi klientami z takich
              krajów jak Polska, Włochy, Dubaj, Niemcy, Dania, Holandia, Anglia, Irlandia, Szwecja, Szwajcaria oraz
              Czechy. Dążę do tego, by każdy projekt, nad którym pracuję, był realizowany z najwyższą starannością i
              dbałością o szczegóły. Moim priorytetem jest, aby spełnić i przekroczyć oczekiwania klienta, tak by mógł
              on polecić moje usługi w przyszłości.
            </Typography>
          </>
        )}
        description2={(
          <>
            <Typography sx={style.desc}>
              W trakcie mojej kariery zawodowej miałem przyjemność i zaszczyt współpracować z wieloma renomowanymi i
              cenionymi markami na całym świecie. Każdy projekt stanowił wyjątkową okazję do rozwijania swoich
              umiejętności i zdobywania nowych doświadczeń, które przyczyniły się do poszerzenia moich horyzontów oraz
              lepszego zrozumienia potrzeb globalnych rynków. Dzięki temu mogłem realizować projekty na najwyższym
              poziomie, łącząc innowacyjne rozwiązania z indywidualnym podejściem do każdego klienta. Owocem tych
              współprac są liczne realizacje, które z dumą prezentuję w swoim portfolio. Każde z tych zadań było dla
              mnie
              inspirującym wyzwaniem, które pozwoliło mi na stworzenie nowatorskich rozwiązań oraz dostarczenie
              wyjątkowych efektów, które spełniały oczekiwania najbardziej wymagających klientów.
            </Typography>
            <Grid container spacing={4}>
              <Grid item md={4} xs={12}>
                <Box sx={style.box}>
                  <img src={google} alt="google" style={style.images}/>
                  <Typography sx={style.desc}>
                    Giganta technologicznego, dla którego tworzenie innowacyjnych rozwiązań webowych było nie tylko
                    wyzwaniem, ale także wyjątkową okazją do współtworzenia przyszłości cyfrowego świata. Dla tej firmy
                    opracowałem system do zarządzania feedami produktowymi w e-commerce, który usprawnił procesy
                    integracji i aktualizacji danych, pozwalając na efektywniejsze zarządzanie ofertą produktów na
                    różnych platformach sprzedażowych.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <Box sx={style.box}>
                  <img src={netflix} alt="netflix" style={style.images}/>
                  <Typography sx={style.desc}>
                    Lidera branży streamingowej, z którym współpraca nad interfejsami i funkcjonalnościami była
                    niezwykle inspirująca, pozwalając na dogłębne zrozumienie potrzeb globalnych użytkowników. Dla tej
                    firmy stworzyłem system do budowania profili użytkowników, co umożliwiło bardziej spersonalizowane
                    rekomendacje i lepsze dostosowanie treści do preferencji widzów na całym świecie.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <Box sx={style.box}>
                  <img src={bbc} alt="bbc" style={style.images}/>
                  <Typography sx={style.desc}>
                    Ikony mediów, gdzie priorytetem były najwyższe standardy jakości oraz wyjątkowa dbałość o detale,
                    aby spełnić oczekiwania milionów widzów na całym świecie. Opracowałem system do walidacji
                    wideo, który zapewniał, że materiały spełniają wymagania jakościowe, zanim trafią na antenę,
                    przyczyniając się do utrzymania wysokiego poziomu emisji.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <Box sx={style.box}>
                  <img src={wedel} alt="wedel" style={style.images}/>
                  <Typography sx={style.desc}>
                    Renomowanej marki czekoladowej, która dzięki innowacjom cyfrowym rozszerzyła swoją działalność,
                    docierając do nowych grup odbiorców i umacniając swoją pozycję na rynku. Dla tej marki realizowałem
                    aranżację wnętrza lokalu w Krakowie, bazując na projekcie przygotowanym przez architekta, co
                    pozwoliło stworzyć przestrzeń spójną z charakterem marki i przyciągającą klientów.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <Box sx={style.box}>
                  <img src={hrlink} alt="hrlink" style={style.images}/>
                  <Typography sx={style.desc}>
                    Specjalisty w dziedzinie HR, którego priorytetem było opracowanie narzędzi i platform
                    usprawniających proces rekrutacji, czyniąc go bardziej intuicyjnym i efektywnym, aby lepiej
                    odpowiadał na potrzeby zarówno pracodawców, jak i kandydatów.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <Box sx={style.box}>
                  <img src={morizon} alt="morizon" style={style.images}/>
                  <Typography sx={style.desc}>
                    Specjalisty w dziedzinie finansów, gdzie opracowałem bota do obsługi kredytów hipotecznych dla
                    Morizon Finanse, integrując go z systemami wszystkich banków, co znacząco usprawniło i
                    zautomatyzowało proces uzyskiwania ofert kredytowych, czyniąc go bardziej intuicyjnym i efektywnym
                    dla klientów.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      />
      <ContactBanner/>
    </>
  )
}

export default AboutPage
