import Portfolio from '../../components/organisms/Portfolio/Portfolio'
import SizeWindow from '../../components/atoms/SizeWindow/SizeWindow'

const PortfolioPage = () => {
  return (
    <SizeWindow>
      <Portfolio/>
    </SizeWindow>
  )
}

export default PortfolioPage
