import * as React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import PropTypes from 'prop-types'

const RadioButtons = ({ name, label, elements, disabled, onChange }) => {
  return (
		<FormControl>
			{label && <FormLabel id={name}>{label}</FormLabel>}
			<RadioGroup
				aria-labelledby={name}
				defaultValue="female"
				name={name}
			>
				{
					elements.map((el, index) => (
						<FormControlLabel
							key={index}
              onChange={onChange}
							disabled={disabled}
							value={el.value}
							control={<Radio/>}
							label={el.label}
						/>
					))
				}
			</RadioGroup>
		</FormControl>
  )
}

RadioButtons.defaultProps = {
  label: '',
  disabled: false,
}

RadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  elements: PropTypes.array.isRequired,
}

export default RadioButtons
