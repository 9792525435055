import {useEffect} from 'react'
import {Button, Grid, Typography} from '@mui/material'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import UserHelper from '../../helpers/UserHelper/UserHelper'
import {useSnackbar} from 'notistack'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import CookiesHelper from '../../helpers/CookiesHelper/CookiesHelper'
import Seo from '../../components/molecules/Seo/Seo'
import {useTheme} from '@mui/styles'
import Box from '@mui/material/Box'
import background from '../../assets/images/pic2.png'
import {useHistory} from 'react-router'

const LoginPage = (props) => {
  const theme = useTheme()
  const history = useHistory()
  const {enqueueSnackbar} = useSnackbar()
  const formData = {
    elements: [
      {
        name: 'email',
        type: 'email',
        label: 'Email',
        validationType: 'string'
      },
      {
        name: 'password',
        type: 'password',
        label: 'Hasło',
        helperText: 'Hasło musi się składać z min. 8 znaków.',
        validationType: 'string'
      },
      {
        type: 'button',
        value: 'Zaloguj'
      }
    ]
  }
  const style = {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      height: 'calc(100vh - 80px)',
    },
    box: {
      background: 'white',
      fontSize: BreakpointsHelper.isMobile() ? '24px' : '25px',
      fontWeight: 700,
      padding: BreakpointsHelper.isMobile() ? '15px' : '50px',
      maxWidth: BreakpointsHelper.isMobile() ? 'none' : '320px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      border: '1px solid black',
    },
    image: {
      height: '100%',
      maxHeight: 'calc(100vh - 120px)',
      position: 'absolute',
      bottom: '0',
    },
    desc: {
      maxWidth: '320px',
      fontSize: '16px',
      fontWeight: BreakpointsHelper.isMobile() ? '400' : '300',
      textTransform: 'none',
      textAlign: 'center',
      marginTop: '5px',
      marginBottom: '20px',
      color: '#5E626F',
    },
    icon: {
      marginRight: '5px'
    }
  }
  const createData = async (res) => {
    const data = {
      accessToken: res?.res?.accessToken,
      uid: res?.res?.uid,
      email: res?.res?.email,
      photoURL: res?.res?.photoURL,
      role: res?.database?.role || 'user',
      phone: res?.database?.phone,
      name: res?.database?.name,
      apartment: res?.database?.apartment,
      street: res?.database?.street,
      house: res?.database?.house,
      defaultLang: res?.database?.defaultLang
    }
    LocalStorageHelper.set('user', data)
    return data
  }
  const handleLogin = (e) => {
    UserHelper.login(e).then(res => {
      if (res) {
        if (res?.res?.emailVerified) {
          createData(res).then(res => {
            if (res) {
              history.push('/profile')
            }
          })
        }
        if (!res?.res?.emailVerified) enqueueSnackbar('Dane do logowanie są niepoprawne, bądź konto nie jest aktywowane z emaila za pomocą linku.', {variant: 'warning'})
      } else enqueueSnackbar('Wystąpił problem z logowaniem.', {variant: 'error'})
    }).catch(() => {
      enqueueSnackbar('Dane do logowanie są niepoprawne.', {variant: 'error'})
    })
  }
  useEffect(() => {
    if (LocalStorageHelper.get('user')) {
      history.push('/profile')
    }
  }, [])
  return (
    <>
      <Seo data={{ 'seo: title': `${theme?.config?.seo?.company} - Logowanie`}}/>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12} sx={{ background: '#f6f6f6' }}>
          <img src={background} alt={'background image login page'} style={style.image} />
        </Grid>
        <Grid item md={6} xs={12}>
          <Box sx={style.root}>
            <Box sx={style.box}>
              Logowanie
              <div style={style.desc}>
                Zaloguj się za pomocą emaila oraz hasła.
              </div>
              <FormGenerator data={formData} submit={(e) => handleLogin(e)} {...props}/>
              <Button
                sx={{marginBottom: '10px', maxWidth: '320px', minHeight: '40px' }}
                fullWidth
                variant="outlined"
                onClick={() => props?.location?.history?.push('/register')}
              >
                Zarejestruj
              </Button>
              <Typography
                sx={{marginBottom: '20px', marginTop: '20px', textTransform: 'lowercase', fontWeight: '700'}}
              >
                Nie pamiętasz hasła ?
              </Typography>
              <Button
                sx={{ marginBottom: '10px', minHeight: '40px' }}
                fullWidth
                variant="outlined"
                onClick={() => props?.location?.history?.push('/reset')}
              >
                Zresetuj hasło
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default LoginPage
